import React, { useEffect ,FC} from 'react';
import { SlArrowLeft,SlArrowRight } from "react-icons/sl";
import './Recognition.css';
import { Image } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const images = [...Array(13)].map((_, index) => require(`../Images/Recognition/${index + 1}.png`));

// const recognitionsArray = [
//     { image: images[0], title: "Winner", url: '' },
//     { image: images[1], title: "Community Action Grant Recipient", url: '' },
//     { image: images[2], title: "Winner", url: '' },
//     { image: images[3], title: "Winner", url: '' },
//     { image: images[4], title: "SAAS Winner", url: '' },
//     { image: images[5], title: "Winner", url: '' },
//     { image: images[6], title: "Semi-Finalist (Ongoing)", url: '' },
//     { image: images[7], title: "LA Winner", url: '' },
//     { image: images[8], title: "AZ Inno Under 25", url: 'https://www.bizjournals.com/phoenix/inno/stories/news/2023/09/15/az-inno-25-under-25.html' },
//     { image: images[9], title: "Grant recipient", url: '' },
//     { image: images[10], title: "Featured", url: 'https://www.statepress.com/article/2023/02/open-pitch-week-entrepreneurship#' },
//     { image: images[11], title: "Featured", url: 'https://fullcircle.asu.edu/features/student-entrepreneurs-pitch-innovative-ideas/' },
//     { image: images[12], title: "Featured", url: 'https://www.wsj.com/business/entrepreneurship/startup-founders-fret-over-getting-fired-like-sam-altman-1c91917c?st=py1v0zfljerm6gr&reflink=article_imessage_share' },
//   ];

  const recognitionsArray = [
    { image: images[0], title: "Winner", url: '' ,date:'Spring 2022',text:'The biannual Open Pitch Week invites student entrepreneurs from all around ASU and the Maricopa Community Colleges an opportunity to pitch their ideas, gain feedback, and network with other students who share their interest in innovation and entrepreneurship'},
    { image: images[1], title: "Community Action Grant Recipient", url: '',date:'Spring 2022',text:'Students can fund initiatives that affect their community in a certain field of study through the ASU Changemaker Community Action Grants. These projects may embrace social impact, entrepreneurship and innovation projects, collaborations and ventures worldwide, prototypes, and more. After the team succeeded in applying for the grant, they were awarded the coveted grant' },
    { image: images[2], title: "Winner", url: '',date:'Autumn 2022',text:'The mission of the biannual Hool Coury Law Tech Venture Challenge is to strategically establish early-stage technology firms that address market issues.Regardless of options for additional funding or assistance, the Tech Venture Challenge assists all sponsored companies in creating scalable business models' },
    { image: images[3], title: "Winner", url: '' ,date:'July 2022',text:'The New Venture Challenge is an annual, application-based, eight-week course that leads to a competition for significant investments in the business and aims to empower student entrepreneurs to build profitable companies'},
    { image: images[4], title: "SAAS Winner", url: '',date:'Winter 2022',text:'The TENWEST Impact Festival, which was founded by Startup Tucson in 2015 and takes place in Downtown Tucson each November, honors the expanding innovation, creative, and entrepreneurial ecosystems in Southern Arizona' },
    { image: images[5], title: "Winner", url: '',date:'Spring 2024',text:'The ASU Innovation Open aims to support and promote academics who are interested in founding hard tech ventures. For its student entrants looking to enter a broad spectrum of industries, the ASUio offers essential venture coaching and funding' },
    { image: images[6], title: "Semi-Finalist (Ongoing)", url: '',date:'Winter 2024',text:"The objective of NextEra Energy Investments' Seed Competition is to find entrepreneurs with innovative solutions in the fields of cybersecurity, data and artificial intelligence, decarbonization, and energy transition who wish to collaborate with NextEra Energy and its affiliates to build a better future" },
    { image: images[7], title: "LA Winner", url: 'https://entrepreneurship.engineering.asu.edu/2023/03/asus-startups-compete-in-the-annual-tech-devils-innovation-retreat-pitch-showcase/',date:'Autumn 2023',text:'Fifteen of the top Venture Devils startup founders attended the Tech Devils Annual Innovation Retreat at the ASU California Center in Los Angeles, where we engaged against other teams to secure a pitch position at the next Hool Coury Law Tech Venture Challenge' },
    { image: images[8], title: "AZ Inno Under 25",date:'Autumn 2023', url: 'https://www.bizjournals.com/phoenix/inno/stories/news/2023/09/15/az-inno-25-under-25.html',text:"The group was pleased to be featured in AZ Inno Under 25 2023, where our CEO was one of eight emerging entrepreneurs leading the way in technology through their business ventures in Arizona." },
    { image: images[9], title: "Grant recipient", url: '',date:'Autumn 2022',text:'The Innovation Corps (I-CorpsTM) program of the U.S. National Science Foundation is a seven-week immersive, entrepreneurial experiential training program that enables scientists and engineers to implement their novel concepts for commercial and industrial applications with funding awarded by the NSF' },
    { image: images[10], title: "Featured", url: 'https://www.statepress.com/article/2023/02/open-pitch-week-entrepreneurship#',date:'Spring 2022',text:'The team received an honorary mention about its participation in the ASU Open Pitch Week and winning the Crowd Favorite Pitch accolade at the Spring 2022 event' },
    { image: images[11], title: "Featured", url: 'https://fullcircle.asu.edu/features/student-entrepreneurs-pitch-innovative-ideas/',date:'Spring 2023',text:'The article by the Full Circle ASU covers our participation in the Tech Devils Annual Innovation Retreat hosted at the ASU California Center in Southern California. Learn more about our competitors and our performance by following up this article' },
    { image: images[12], title: "Featured", url: 'https://www.wsj.com/business/entrepreneurship/startup-founders-fret-over-getting-fired-like-sam-altman-1c91917c?st=py1v0zfljerm6gr&reflink=article_imessage_share',date:'Winter 2023',text:'Follow up on our thoughts about startup founders who are terminated prematurely in this article by the Wall Street Journal and get an in-depth view on the positions of startup founders reagarding the matter' },
  ];

  interface ArrowProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
  }

  const NextArrow: FC<ArrowProps> = ({ className, style, onClick }) => {
    return (
      <div
      className={className}
        style={ style  }
        onClick={onClick}
      >
        <SlArrowRight className='arrow-icon' /> {/* Green arrow */}
      </div>
    );
  };
  
  const PrevArrow: FC<ArrowProps> = ({ className, style, onClick }) => {
    return (
      <div
        
        className={className} 
        style={ style  }
        onClick={onClick}
      >
        <SlArrowLeft className='arrow-icon' /> {/* Green arrow */}
      </div>
    );
  };

const Recognition: React.FC = () => {



    useEffect(() => {
        document.title = 'Recognition | BreatheEV';
    }, []);


    const cards = recognitionsArray.map((card, index) => (
        <div key={'cards-' + index} className="col-sm-12 col-md-6 py-3">
          <div className='recognition-clm'>
            {card.url ? (
              <a href={card.url} target="_blank" rel="noopener noreferrer">
                <h2 className="card-text">{card.title}</h2>
                <Image src={card.image} />
              </a>
            ) : (
              <>
                <h2 className="card-text">{card.title}</h2>
                <Image src={card.image} />
              </>
            )}
          </div>
        </div>
      ));

      var settings = {
        arrows:true,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        nextArrow:<NextArrow/>,
        prevArrow:<PrevArrow/>
        
      };
      



    return (
        <main id="main">
            <section id="recognition-main">
                <div className="container text-center">
                    <h1>OUR Recognition</h1>
                </div>
            </section>

            <section id="recognition-row" className="container recognition-row  mt-3 bg-light mb-5">
                <div className="container-fluid">

                    <div className="row">
                        {/* {cards} */}
                        <Slider {...settings}>
                              {
                                recognitionsArray.map((obj,index)=>{
                                  const {image,title,url,text,date} = obj;

                                  return(
                                    <article key={index}>
                                        <a href={url} target="_blank" rel="noopener noreferrer">
                                        <img src={image}  className='slick-container-img'/>
                                        </a>
                                        <h5 className='recognition-position-achieved'>{title}</h5>
                                        <p className='recognition-position-achieved-date'>{date}</p>
                                        <p className='recognition-text'>{text}</p>
                                    </article>
                                  )

                                })
                              }
         
                        </Slider>
                    </div>

                    <div className='recognition-stamps'>
                      {
                        recognitionsArray.map((obj,index)=>{

                          return <a href={obj.url} key={index} target="_blank"><img src={obj.image} /></a>
                        })
                      }

                    </div>

                </div>
            </section>

        </main>
    );
};

export default Recognition;
