import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import heroBGImage from '../Images/background.svg';
import BannerBg from '../Images/banner.jpg';
import visionStatement from '../Images/vision-statement-pana.svg';
// import chargersImage from '../Images/chargers-green.jpg';
import chargersImage from '../Images/chargers.jpg';
import chargersImageMobile from '../Images/chargers-mobile.jpg';
import Image from 'react-bootstrap/Image';
import HeroImage from './HeroImage';
import { Link } from 'react-router-dom';
import VideoModal from './components/VideoLoop/VideoModal';

import { AiFillThunderbolt } from "react-icons/ai";



const Home: React.FC = () => {

    const [showMore, setShowMore] = useState(false);
    const [isVideoLoading, setIsVideoLoading] = useState(true);


    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const scrollToTop = () => {
        setTimeout(()=> {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
    };

    useEffect(() => {
        document.title = 'Home | BreatheEV';
    }, []);

    const [VideoModalView, setVideoModalView] = useState(false);



    // aspectRatio start
    const [isMobileSize, setIsMobileSize] = useState(false);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (videoRef.current) {
                // videoRef.current.play();
            }
        }, 5000);

        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const aspectRatio = parseFloat((windowSize.width / windowSize.height).toFixed(2));

    useEffect(() => {
        const maxAspectRatio = 1.66;
        const minAspectRatio = 2.72;
        const maxWidth = 910;

        if ((aspectRatio <= maxAspectRatio || aspectRatio >= minAspectRatio || windowSize.width <= maxWidth)) {
            setIsMobileSize(true);
        } else {
            setIsMobileSize(false);
        }
    }, [windowSize, aspectRatio]);
    // aspectRatio end




    return (
        <main id="main">
            {false && <section id="hero" style={{ backgroundImage: `url(${heroBGImage})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%' }}>
                <div className="">
                    <h1>PREMIER EV CHARGING SOLUTIONS</h1>
                    <h2>We make it easy to bring charging to your properties</h2>
                    <div className='home-cta'>
                        {/* <Link onClick={scrollToTop} to="/partner-with-us" className="btn-get-started scrollto">Talk to our team</Link>
                        <Link onClick={scrollToTop} to="/partner-with-us" className="btn-get-started secondary scrollto">Install Chargers</Link>  */}
                        <Link onClick={scrollToTop} to="/partner-with-us" className="btn-get-started scrollto">Install Chargers</Link>
                    </div>
                </div>
                <HeroImage />
            </section>}

            {true && <section id="video-hero" className={`${'bg-video'} ${isVideoLoading ? 'loading' : ''} ${isMobileSize ? 'is-mobile' : ''}`}>

                <video
                    ref={videoRef}
                    className="video"
                    src="https://d3m0dzbeurmsd7.cloudfront.net/Videos/LoopVideo1.mp4"
                    // src="https://s3.us-west-2.amazonaws.com/app.breathe-ev/Videos/LoopVideo1.mp4?dfdsfxvtytdgfd"
                    poster={BannerBg}
                    autoPlay
                    loop
                    muted
                    playsInline
                    onContextMenu={(e) => e.preventDefault()}
                    onLoadStart={() => setIsVideoLoading(true)}
                    // onWaiting={() => setIsVideoLoading(true)}
                    onLoadedData={() => setIsVideoLoading(false)}
                    onCanPlay={() => setIsVideoLoading(false)}
                />
                <div className='loading'>
                    <AiFillThunderbolt />
                </div>

                <div className="video-text">
                    <h1>PREMIER EV CHARGING SOLUTIONS</h1>
                    <h2>We make it easy to bring charging to your properties</h2>
                    <div className='home-cta'>
                        {/* <Link onClick={scrollToTop} to="/partner-with-us" className="btn-get-started scrollto">Talk to our team</Link> */}
                        <Link onClick={scrollToTop} to="/partner-with-us" className="btn btn-get-started scrollto">Install Chargers</Link>
                        <button onClick={()=>setVideoModalView(true)} className="btn btn-get-started secondary scrollto ">Watch Full Video</button> 
                    </div>
                </div>

                <VideoModal modalVisible={VideoModalView} closeModal={() => setVideoModalView(false)} />
                    
            </section>}

            <section id="why-us" className="why-us">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-5 d-flex align-items-stretch">
                            <div className="content">
                                <h3>Why BreatheEV?</h3>
                                {/* <p>How BreatheEV accelerates our EV future</p> */}
                                <p>
                                    {/* BreatheEV stands at the forefront of the EV revolution, offering swift, reservable charging options tailored for commercial real estate. We don’t just provide charging; we offer a comprehensive hardware and software solution that elevates the entire experience. BreatheEV delivers reliability, convenience, and innovative revenue opportunities for properties, ensuring that our network is built to exceed the future demands and expectations of EV owners and properties alike. */}
                                    BreatheEV's fully-managed EV charging solution offers hassle-free charging with installation, maintenance, daily operation, and user management. Our reservation system secures charging spots, optimizing utilization and improving satisfaction. Partnering with us boosts revenue, advances sustainability, and reduces carbon footprint with zero management effort. Choose BreatheEV for convenient, future-proof, and cost-effective charging solutions.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-7 d-flex align-items-stretch">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-xl-3 d-flex align-items-stretch">
                                        <Link  onClick={scrollToTop}  to={'/product/multifamily-ev-charging'} className="icon-box mt-4 mt-xl-0">
                                            <h4>Multifamily <span>EV Charging</span></h4>
                                            {/* <h4>Seamless Integration</h4> */}
                                            {/* <p>BreatheEV simplifies EV charging for properties with an end-to-end turnkey solution.</p> */}
                                            {/* {showMore && <p>From installation of hardware to ongoing software operation and maintenance, we manage every aspect of the network, ensuring consistent uptime and efficiency. This approach removes the burden of network management for you, allowing you to reap the benefits without the hassle. By partnering with us, properties enhance their amenities, increase revenue generation, and property valuation. Experience true seamless integration with BreatheEV, where we do the work, and you enjoy the rewards. </p>} */}
                                        </Link>
                                    </div>
                                    <div className="col-xl-3 d-flex align-items-stretch">
                                        <Link  onClick={scrollToTop}  to={'/product/commercial-ev-charging'} className="icon-box mt-4 mt-xl-0">
                                            <h4>Commercial <span>EV Charging</span></h4>
                                            {/* <p>Elevate your property's value with BreatheEV's exclusive charging solutions.</p> */}
                                            {/* {showMore && <p>We offer private, reservation-based charging stations, ensuring that your residents never have to compete for a spot. Private access groups ensure that users have access to charging, with the option to open up the network to guest users as well. Our user-friendly platform guarantees that a charge is always just a reservation away, making electric vehicle ownership more convenient than ever.</p>} */}
                                        </Link>
                                    </div>

                                    <div className="col-xl-3 d-flex align-items-stretch">
                                        <Link  onClick={scrollToTop}  to={'/product/university-ev-charging'} className="icon-box mt-4 mt-xl-0">
                                            <h4>University <span>EV Charging</span></h4>
                                            {/* <p>Partner with BreatheEV to enhance your property's appeal and align with vital environmental goals.</p> */}
                                            {/* {showMore && <p>Our charging solutions contribute to your building's sustainability profile, potentially aiding in achieving eco-certifications. By integrating BreatheEV, properties take a proactive step towards reducing their carbon footprint, a move highly valued in today’s world. This partnership signals a commitment to sustainable practices and positions properties as leaders in the green revolution, enhancing resident satisfaction and attracting eco-minded tenants. With BreatheEV, your property doesn’t just adapt to an eco-friendly future; it helps shape it.</p>} */}
                                        </Link>
                                    </div>

                                    <div className="col-xl-3 d-flex align-items-stretch">
                                        <Link  onClick={scrollToTop}  to={'/product/ocpp-management-software'} className="icon-box mt-4 mt-xl-0">
                                            <h4>BreatheEV Software</h4>
                                            {/* <p>Partner with BreatheEV to enhance your property's appeal and align with vital environmental goals.</p> */}
                                            {/* {showMore && <p>Our charging solutions contribute to your building's sustainability profile, potentially aiding in achieving eco-certifications. By integrating BreatheEV, properties take a proactive step towards reducing their carbon footprint, a move highly valued in today’s world. This partnership signals a commitment to sustainable practices and positions properties as leaders in the green revolution, enhancing resident satisfaction and attracting eco-minded tenants. With BreatheEV, your property doesn’t just adapt to an eco-friendly future; it helps shape it.</p>} */}
                                        </Link>
                                    </div>

                                    <div className="col-xl-12 d-flex align-items-stretch">
                                        <Link  onClick={scrollToTop}  to={'/product/BERT'} className="icon-box mt-4 mt-xl-0 full-width">
                                            <h4>BreatheEV Revenue Tool</h4>
                                        </Link>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-xl-12 float-end">
                                        <button type='button' className='btn btn-link btn-sm text-secondary float-end' onClick={toggleShowMore}>
                                            {showMore ? 'Read Less' : 'Read More'}
                                        </button>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>

                </div>

                {/* <VideoLoop /> */}
            </section>

            {/* style={{ backgroundImage: `url(${btmImage})`, backgroundRepeat: 'repeat-x',backgroundPositionY:'calc(100% + 10px)' }} */}
            
            <section id="vision" className="vision  mt-3 bg-light" >
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-xl-5 col-lg-6 d-flex justify-content-center align-items-stretch position-relative">
                            <Image src={visionStatement} className='vision-image' />
                        </div>

                        <div className="col-xl-7 col-lg-6 boxes d-flex flex-column align-items-stretch justify-content-center px-sm-5 py-5 px-lg-5">
                            <h3>VISION</h3>
                            <h5>Reimagining charging at home, the office and beyond</h5>
                            <p>BreatheEV revolutionizes EV charging for multifamily and commercial properties. Our seamless solutions enhance property value, boost convenience for residents and employees, and promote sustainability. Partnering with BreatheEV means zero operational burden and turning EV charging into a hassle-free, revenue-generating amenity.</p>
                        </div>

                    </div>

                </div>
            </section>
            {/* style={{ backgroundImage: `url('${chargersImage}')` }} */}
            <section id="services" className="services">
                <div></div>
                <img className='services-image-view' style={{width:'100%'}} src={chargersImage} />
                <img className='services-image-mobile-view' style={{width:'100%'}} src={chargersImageMobile} />
            </section>

        </main>
    );
};

export default Home;
