import React, { useEffect, useState } from 'react';
import './Purchase.css';
import chargerImage from '../../Images/WallBox_Residential-Complex_Pulsar.jpg';
import BlurBackground from '../../Images/background-image.jpg';
import Image from 'react-bootstrap/Image';
import NavigationPage from '../components/NavigationPage';
import { Accordion, Card } from 'react-bootstrap';
import InputNumber from '../components/InputNumber';
import Email from '../../components/Email';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';


const Purchase: React.FC = () => {

    useEffect(() => {
        document.title = 'Purchase | BreatheEV';
    }, []);

    const [mainOption, setMainOption] = useState<string>('');
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>(false);

    const [selectedSystem, setSelectedSystem] = useState('');
    const [parkingSpots, setParkingSpots] = useState('');
    const [quantity, setQuantity] = useState<number | ''>('');

    const [propertyType, setPropertyType] = useState<string>('');
    const [propertyTypeOther, setPropertyTypeOther] = useState<boolean>(false);
    const [propertyAddress, setPropertyAddress] = useState<string>('');
    const [existingChargerBrand, setExistingChargerBrand] = useState<string>('');
    const [existingChargerModel, setExistingChargerModel] = useState<string>('');

    const [installation, setInstallation] = useState<string>('');


    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');

    const [onSaving, setOnSaving] = useState<boolean>(false);


    const { propertyType: propertyTypeInput } = useParams<{ propertyType: string|undefined }>();

    const transformOrRevertString = (input: string | undefined, direction: 'transform' | 'revert'): string => {
        if (!input) {
            return '';
        }
    
        if (direction === 'transform') {
            let transformed = input.replace(/-/g, ' ');
            transformed = transformed
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
    
            return transformed;
        } else if (direction === 'revert') {
            let reverted = input.toLowerCase();
            reverted = reverted.replace(/ /g, '-');
            return reverted;
        } else {
            return '';
        }
    };

    useEffect(() => {
        setPropertyType(transformOrRevertString(propertyTypeInput, 'transform'));
    }, []);



    const navigate = useNavigate();
    const location = useLocation();
  
    const handleTabClick = (path: string) => {
      navigate(path, { replace: true });
    };

    useEffect(() => {
        if(propertyType == 'Commercial' ||  propertyType == 'Multi Family' || propertyType == 'University' || propertyType == 'Office') {
            handleTabClick(`/product/purchase/${transformOrRevertString(propertyType, 'revert')}`);
        }
        else {
            handleTabClick(`/product/purchase`);
        }
    }, [propertyType]);



    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
    };    

    useEffect(() => {
        const quantityNumber = typeof quantity === 'string' ? parseInt(quantity) : quantity;
        
        switch (quantityNumber) {
            case 1:
                setParkingSpots('0-20');
                break;
            case 2:
                setParkingSpots('20-50');
                break;
            case 3:
                setParkingSpots('50-100');
                break;
            case 4:
                setParkingSpots('100-200');
                break;
            default:
                if (quantityNumber >= 5) {
                    setParkingSpots('200+');
                } else {
                    setParkingSpots('');
                }
                break;
        }
    }, [quantity]);

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhoneNumber = (n: string): boolean => /^\+?1?\d{10,11}$/.test(n);

    const handleSave = async (type: string) => {
        setOnSaving(true);
        const emailSender = new Email();
        const success = await emailSender.makeEmail(name, email, 'New Connection', [
            { head: 'Installation Type', value: mainOption != '' ? (mainOption == 'new' ? 'New BreatheEV Charger Install' : 'Onboard Your Chargers to the BreatheEV Network') : '' },
            { head: 'Design', value: selectedSystem != '' ? (selectedSystem == 'own-system' ? 'Design own system' : 'BreatheEV design') : '' },
            { head: 'Property Type', value: propertyType },
            { head: 'Property Address', value: propertyAddress },
            { head: 'Existing Charger Brand', value: existingChargerBrand },
            { head: 'Existing Charger Model', value: existingChargerModel },
            { head: 'Installation', value: installation },
            { head: 'Total Chargers', value: quantity?.toString() },
            { head: 'Name', value: name },
            { head: 'Email', value: email },
            { head: 'Phone', value: phone },
        ]);
        if (success) {
            setIsFinished(true);
            setOnSaving(false);
            scrollToTop();
        } else {
            toast.error(`Error...`, { position: toast.POSITION.TOP_RIGHT });
        }

    }

    const navigateToBegin = () => {

        setMainOption('')
        setIsStarted(false);
        setIsFinished(false);
    
        setSelectedSystem('');
        setParkingSpots('');
        setQuantity('');
    
        setPropertyType('');
        setPropertyTypeOther(false);
        setPropertyAddress('');
        setExistingChargerBrand('');
        setExistingChargerModel('');
    
        setInstallation('');
    
        setName('');
        setEmail('');
        setPhone('');
    }


    return (
        <main id="main">
            <section id="purchase-main">
                <div className="container text-center">
                    <h1>Purchase</h1>
                </div>
            </section>

            <section id="purchase" className="container purchase bg-light">
                <div className="container-fluid">

                    <div className="row">
                    {/* backgroundImage: `url(${BlurBackground})` */}
                        <div style={{ backgroundImage: `url(${chargerImage})`, WebkitBackgroundSize:'100%', backgroundRepeat:'no-repeat', backgroundPosition:"bottom"}} className="col-xl-8 col-lg-6 d-flex justify-content-center align-items-stretch position-relative purchase-left-block">
                            <div className='image-container'>
                                {/* <Image src={chargerImage} className='purchase-image' /> */}
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-6 boxes d-flex flex-column align-items-stretch  py-5 px-lg-5 purchase-right-block">
                            <div className='mb-1'>



                                {!isStarted || isFinished ?
                                    <>
                                        {!isFinished ?
                                            <>
                                                <div className='head-join'>Ready to join the BreatheEV revolution?</div>
                                                <h4 className='sub-head'>Let’s design your system</h4>
                                                <hr />
                                                {/* <Image src={maintenanceImage} /> */}
                                                <div className='selections'>
                                                    <div onClick={() => { setMainOption('new') }} className={`option ${mainOption === 'new' ? 'active' : ''}`}>New BreatheEV Charger Install</div>
                                                    <div onClick={() => { setMainOption('onboard') }} className={`option ${mainOption === 'onboard' ? 'active' : ''}`}>Onboard Your Chargers to the BreatheEV Network</div>
                                                </div>

                                                <div className='buttons'>
                                                    <button disabled={mainOption === '' ? true : false} onClick={() => { setIsStarted(true) }} value={'Next'}>Start</button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div>
                                                    {/* <div className='component-head'>Finished</div> */}
                                                    <h4 className='sub-head'>Finished</h4>
                                                    <hr />
                                                    <div className='component-group'>
                                                        <div className='component-text'>
                                                            <div><b>Thank you for your interest in BreatheEV!</b></div>
                                                            <div>A member of our project management team will be in contact with you within a day.</div>

                                                        </div>
                                                    </div>
                                                    <div className='component-text'>BreatheEV info@breathe-ev.com</div>

                                                </div>
                                            </>
                                        }

                                    </>
                                    :
                                    <>
                                        {mainOption === 'new' ?

                                            <NavigationPage navigateToBegin={navigateToBegin} handleSave={() => { handleSave('new') }} isSaving={onSaving} components={[
                                                {
                                                    canGoNext: selectedSystem != "" ? true : false,
                                                    component:
                                                        <div>
                                                            <h4 className='sub-head top-margin'>Property Details</h4>
                                                            <hr />
                                                            {/* <div className='component-head'>Property Details</div> */}
                                                            <div className='selections'>
                                                                <div onClick={() => { setSelectedSystem('own-system') }} className={`option ${selectedSystem === 'own-system' ? 'active' : ''}`}>Design your own system</div>
                                                                <div onClick={() => { setSelectedSystem('my-system') }} className={`option ${selectedSystem === 'my-system' ? 'active' : ''}`}>Let BreatheEV design my system</div>
                                                            </div>
                                                        </div>
                                                }
                                                ,
                                                {
                                                    canGoNext: (propertyType != "" && propertyAddress != ""),
                                                    component: <>
                                                        {selectedSystem === 'own-system'
                                                            ?
                                                            <div>
                                                                {/* <div className='component-head'>Property Details</div> */}
                                                                <h4 className='sub-head top-margin'>Property Details</h4>
                                                                <hr />
                                                                <div className='selections'>
                                                                    <div className='inputs'>
                                                                        <Accordion className='mt-3' defaultActiveKey="">
                                                                            <Accordion.Item eventKey="1">
                                                                                <Accordion.Header>{propertyType === '' ? 'Property Type' : propertyType}</Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <div className='selections'>
                                                                                        <div onClick={() => { setPropertyType('Commercial'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'Commercial' ? 'active' : ''}`}>Commercial</div>
                                                                                        <div onClick={() => { setPropertyType('Multi Family'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'Multi Family' ? 'active' : ''}`}>Multi Family</div>
                                                                                        <div onClick={() => { setPropertyType('University'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'University' ? 'active' : ''}`}>University</div>
                                                                                        <div onClick={() => { setPropertyType('Office'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'Office' ? 'active' : ''}`}>Office</div>
                                                                                        <div onClick={() => { setPropertyType(''); setPropertyTypeOther(true); }} className={`option ${propertyTypeOther ? 'active' : ''}`}>Other</div>
                                                                                        {propertyTypeOther && <input placeholder='Property Type' value={propertyType} onChange={(e) => setPropertyType(e.target.value)} type='text' />}

                                                                                    </div>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        </Accordion>
                                                                        <input placeholder='Property Address' value={propertyAddress} onChange={(e) => setPropertyAddress(e.target.value)} type='text' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                {/* <div className='component-head'>Property Details</div> */}
                                                                <h4 className='sub-head top-margin'>Property Details</h4>
                                                                <hr />
                                                                <div className='selections'>
                                                                    <div className='inputs'>
                                                                        <Accordion className='mt-3' defaultActiveKey="">
                                                                            <Accordion.Item eventKey="1">
                                                                                <Accordion.Header>{propertyType === '' ? 'Property Type' : propertyType}</Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <div className='selections'>
                                                                                        <div onClick={() => { setPropertyType('Commercial'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'Commercial' ? 'active' : ''}`}>Commercial</div>
                                                                                        <div onClick={() => { setPropertyType('Multi Family'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'Multi Family' ? 'active' : ''}`}>Multi Family</div>
                                                                                        <div onClick={() => { setPropertyType('University'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'University' ? 'active' : ''}`}>University</div>
                                                                                        <div onClick={() => { setPropertyType('Office'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'Office' ? 'active' : ''}`}>Office</div>
                                                                                        <div onClick={() => { setPropertyType(''); setPropertyTypeOther(true); }} className={`option ${propertyTypeOther ? 'active' : ''}`}>Other</div>
                                                                                        {propertyTypeOther && <input placeholder='Property Type' value={propertyType} onChange={(e) => setPropertyType(e.target.value)} type='text' />}

                                                                                    </div>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        </Accordion>
                                                                        <input placeholder='Property Address' value={propertyAddress} onChange={(e) => setPropertyAddress(e.target.value)} type='text' />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                    </>
                                                }
                                                ,
                                                {
                                                    canGoNext: (quantity != ""),
                                                    component: selectedSystem === 'own-system' ? <>
                                                        <div>
                                                            {/* <div className='component-head'>Pricing</div> */}
                                                            <h4 className='sub-head top-margin'>Pricing</h4>
                                                            <hr />
                                                            <div className='component-text'>
                                                                <div><b>BreatheEV Level 2 Charger: No Cost ($0)</b></div>
                                                                <ul>
                                                                    <li>11.5 kW</li>
                                                                    <li>5-Year Warranty</li>
                                                                    <li>Network Connectivity (WiFi or 4G/5G)</li>
                                                                    <li>Connected to BreatheEV Network at No Cost</li>
                                                                    <li>Reservation-Enabled</li>
                                                                </ul>
                                                            </div>

                                                            <div className='inputs no-top-margin'>
                                                                <div className='inputs no-top-margin'>
                                                                    {/* <div className='input-head'>How Many Chargers do I need?</div> */}
                                                                    <InputNumber label='Quantity' value={quantity === '' ? 0 : quantity} minValue={1} maxValue={1000} onChange={(qty) => setQuantity(qty)} />
                                                                </div>

                                                                <Accordion className='mt-3' defaultActiveKey="">
                                                                    <Accordion.Item eventKey="1">
                                                                        <Accordion.Header>How Many Chargers Do I Need?</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div >Total Parking Spots</div>
                                                                            <div className='selections'>
                                                                                <div onClick={() => { setParkingSpots('0-20'); setQuantity(1); }} className={`option ${parkingSpots === '0-20' ? 'active' : ''}`}>0-20</div>
                                                                                <div onClick={() => { setParkingSpots('20-50'); setQuantity(2); }} className={`option ${parkingSpots === '20-50' ? 'active' : ''}`}>20-50</div>
                                                                                <div onClick={() => { setParkingSpots('50-100'); setQuantity(3); }} className={`option ${parkingSpots === '50-100' ? 'active' : ''}`}>50-100</div>
                                                                                <div onClick={() => { setParkingSpots('100-200'); setQuantity(4); }} className={`option ${parkingSpots === '100-200' ? 'active' : ''}`}>100-200</div>
                                                                                <div onClick={() => { setParkingSpots('200+'); setQuantity(5); }} className={`option ${parkingSpots === '200+' ? 'active' : ''}`}>200+</div>
                                                                                {/* <div>Quantity: {quantity}</div> */}
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </div>

                                                        </div>
                                                    </> : null
                                                }
                                                ,
                                                {
                                                    canGoNext: true,
                                                    component: selectedSystem === 'own-system' ? <>
                                                        <div className=''>
                                                            {/* <div className='component-head'>Installation</div> */}
                                                            <h4 className='sub-head top-margin'>Installation</h4>
                                                            <hr />
                                                            <div className='component-text'>
                                                                <div className='mb-2'><b>Turnkey Charger Installation:</b></div>
                                                                <div className='mb-2'>BreatheEV’s team will manage installation from start-to-finish, overseeing the whole process with an onsite project manager. Installation costs vary based on your site.</div>
                                                                {/* <div>Estimated installation cost: <b>$5000 per charger, before incentives </b></div> */}
                                                            </div>

                                                        </div>
                                                    </> : null
                                                }
                                                ,
                                                {
                                                    canGoNext: (name != "" && (email != "" && isValidEmail(email)) && (phone != "" && isValidPhoneNumber(phone))),
                                                    component: <>
                                                        {selectedSystem === 'own-system'
                                                            ?
                                                            <div className=''>
                                                                {/* <div className='component-head'>Proposal</div> */}
                                                                <h4 className='sub-head top-margin'>Proposal</h4>
                                                                <hr />
                                                                <div className='component-text'>
                                                                    <div className='block text-center'><b>New BreatheEV Charger Install</b></div>
                                                                    <div className='block'>Design: <span>Design your own system</span><div className="clearfix"></div></div>
                                                                    <div className='block'>Property Type: <span>{propertyType}</span><div className="clearfix"></div></div>
                                                                    <div className='block'>Property Address: <span>{propertyAddress}</span><div className="clearfix"></div></div>
                                                                    <div className='block'>Total Chargers: <span>{quantity}</span><div className="clearfix"></div></div>
                                                                </div>
                                                                <div className='component-text'>
                                                                    <div><b>Charger details:</b></div>
                                                                    <ul>
                                                                        <li>11.5 kW</li>
                                                                        <li>5-Year Warranty</li>
                                                                        <li>Network Connectivity (WiFi or 4G/5G)</li>
                                                                        <li>Connected to BreatheEV Network at No Cost</li>
                                                                        <li>Reservation-Enabled</li>
                                                                    </ul>
                                                                </div>
                                                                <div className='component-text'>
                                                                    <div className='mb-2'><b>Turnkey Charger Installation:</b></div>
                                                                    <div className='mb-2'>BreatheEV’s team will manage installation from start-to-finish, overseeing the whole process with an onsite project manager. Installation costs vary based on your site.</div>
                                                                </div>
                                                                <div className='inputs no-top-margin'>
                                                                    <input placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} type='text' />
                                                                    <input className={isValidEmail(email) || email === '' ? '' : 'error'} placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} type='text' />
                                                                    <label className='error'>Enter a valid Email address</label>
                                                                    <input className={isValidPhoneNumber(phone) || phone === '' ? '' : 'error'} placeholder='Phone Number' value={phone} onChange={(e) => setPhone(e.target.value)} type='text' />
                                                                    <label className='error'>Enter a valid Phone Number</label>
                                                                    <div className="clearfix"></div>
                                                                </div>
                                                                <hr />
                                                                <div className='component-group'>
                                                                    <div className='component-text'>
                                                                        <b>Total Cost:</b>
                                                                        <div>$0 (hardware & software) +  installation cost</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className=''>
                                                                {/* <div className='component-head'>Proposal</div> */}
                                                                <h4 className='sub-head top-margin'>Proposal</h4>
                                                                <hr />
                                                                <div className='component-text'>
                                                                    <div className='block text-center'><b>New BreatheEV Charger Install</b></div>
                                                                    <div className='block'>Design: <span>Let BreatheEV design my system</span><div className="clearfix"></div></div>
                                                                    <div className='block'>Property Type: <span>{propertyType}</span><div className="clearfix"></div></div>
                                                                    <div className='block'>Property Address: <span>{propertyAddress}</span><div className="clearfix"></div></div>
                                                                    <ul>
                                                                        <li>Custom Site Design: $0</li>
                                                                        <li>BreatheEV Chargers: $0</li>
                                                                        <li>BreatheEV Software: $0</li>
                                                                        <li>Turnkey Installation</li>
                                                                    </ul>
                                                                </div>
                                                                <div className='inputs no-top-margin'>
                                                                    <input placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} type='text' />
                                                                    <input className={isValidEmail(email) || email === '' ? '' : 'error'} placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} type='text' />
                                                                    <label className='error'>Enter a valid Email address</label>
                                                                    <input className={isValidPhoneNumber(phone) || phone === '' ? '' : 'error'} placeholder='Phone Number' value={phone} onChange={(e) => setPhone(e.target.value)} type='text' />
                                                                    <label className='error'>Enter a valid Phone Number</label>
                                                                    <div className="clearfix"></div>
                                                                </div>
                                                                <hr />
                                                                <div className='component-group'>
                                                                    <div className='component-text'>
                                                                        <b>Total Cost:</b>
                                                                        <div>$0 (hardware & software) +  installation cost</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }

                                            ]} />
                                            :
                                            <NavigationPage navigateToBegin={navigateToBegin} handleSave={() => { handleSave('onboard') }} isSaving={onSaving} components={[
                                                {
                                                    canGoNext: (propertyType != "" && propertyAddress != "" && existingChargerBrand != "" && existingChargerModel != "" && quantity != ""),
                                                    component: <div className=''>
                                                        <h4 className='sub-head top-margin'>Property Details</h4>
                                                        <hr />
                                                        <div className='inputs'>
                                                            <Accordion className='mt-3' defaultActiveKey="">
                                                                <Accordion.Item eventKey="1">
                                                                    <Accordion.Header>{propertyType === '' ? 'Property Type' : propertyType}</Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <div className='selections'>
                                                                            <div onClick={() => { setPropertyType('Commercial'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'Commercial' ? 'active' : ''}`}>Commercial</div>
                                                                            <div onClick={() => { setPropertyType('Multi Family'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'Multi Family' ? 'active' : ''}`}>Multi Family</div>
                                                                            <div onClick={() => { setPropertyType('University'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'University' ? 'active' : ''}`}>University</div>
                                                                            <div onClick={() => { setPropertyType('Office'); setPropertyTypeOther(false); }} className={`option ${propertyType === 'Office' ? 'active' : ''}`}>Office</div>
                                                                            <div onClick={() => { setPropertyType(''); setPropertyTypeOther(true); }} className={`option ${propertyTypeOther ? 'active' : ''}`}>Other</div>
                                                                            {propertyTypeOther && <input placeholder='Property Type' value={propertyType} onChange={(e) => setPropertyType(e.target.value)} type='text' />}

                                                                        </div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </Accordion>
                                                            <input placeholder='Property Address' value={propertyAddress} onChange={(e) => setPropertyAddress(e.target.value)} type='text' />
                                                            <input placeholder='Existing Charger Brand' value={existingChargerBrand} onChange={(e) => setExistingChargerBrand(e.target.value)} type='text' />
                                                            <input placeholder='Existing Charger Model' value={existingChargerModel} onChange={(e) => setExistingChargerModel(e.target.value)} type='text' />
                                                            <InputNumber label='Number of Chargers' value={quantity === '' ? 0 : quantity} minValue={1} maxValue={1000} onChange={(qty) => setQuantity(qty)} />
                                                        </div>
                                                    </div>
                                                }
                                                ,
                                                {
                                                    canGoNext: true,
                                                    component: <div>
                                                        {/* <div className='component-head'>Pricing</div> */}
                                                        <h4 className='sub-head top-margin'>Pricing</h4>
                                                        <hr />
                                                        <div className='component-text'>
                                                            <div><b>BreatheEV Software</b></div>

                                                            <ul>
                                                                <li>$0 Annual Cost</li>
                                                                <li>Reservation-Enabled Charging</li>
                                                                <li>Utilization Maximizing Features</li>
                                                                <li>Live Status Dashboard</li>
                                                                <li>Utilization and Uptime Reporting</li>
                                                                <li>Payment Integration</li>
                                                                <li>Multi-Site Management</li>
                                                                <li>User Verification</li>
                                                                <li>Guest Networks</li>
                                                                <li>Energy Management</li>
                                                            </ul>

                                                        </div>

                                                    </div>
                                                }
                                                ,
                                                {
                                                    canGoNext: (installation != "" ? true : false),
                                                    component: <div>
                                                        {/* <div className='component-head'>Installation</div> */}
                                                        <h4 className='sub-head top-margin'>Installation</h4>
                                                        <hr />
                                                        <div className='selections'>
                                                            <div className='component-group'>
                                                                <div className='component-text'>Join the BreatheEV charging network yourself or with a contractor of your choice. We will ship you any necessary networking hardware for installation, and connect with your on-site team to set up your chargers on our network at your location.</div>
                                                                <div onClick={() => { setInstallation('DIY with Remote BreatheEV Engineering and Product Support'); }} className={`option ${installation === 'DIY with Remote BreatheEV Engineering and Product Support' ? 'active' : ''}`}>DIY with Remote BreatheEV Engineering and Product Support</div>
                                                            </div>
                                                            <div className='component-group'>
                                                                <div className='component-text'>BreatheEV’s team will manage onboarding from start-to-finish, overseeing the whole process with an onsite project manager. Onboarding costs vary based on your site.</div>
                                                                <div onClick={() => { setInstallation('Turnkey Charger Onboarding'); }} className={`option ${installation === 'Turnkey Charger Onboarding' ? 'active' : ''}`}>Turnkey Charger Onboarding</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                ,
                                                {
                                                    canGoNext: (name != "" && (email != "" && isValidEmail(email)) && (phone != "" && isValidPhoneNumber(phone))),
                                                    component: <div className=''>
                                                        {/* <div className='component-head'>Proposal</div> */}
                                                        <h4 className='sub-head top-margin'>Proposal</h4>
                                                        <hr />
                                                        <div className='component-text'>
                                                            <div className='block text-center'><b>Onboard Your Chargers to the BreatheEV Network</b></div>
                                                            <div className='block'>Property Type: <span>{propertyType}</span><div className="clearfix"></div></div>
                                                            <div className='block'>Property Address: <span>{propertyAddress}</span><div className="clearfix"></div></div>
                                                            <div className='block'>Existing Charger Brand <span>{existingChargerBrand}</span><div className="clearfix"></div></div>
                                                            <div className='block'>Existing Charger Model <span>{existingChargerModel}</span><div className="clearfix"></div></div>
                                                            <div className='block'>Number of Chargers: <span>{quantity}</span><div className="clearfix"></div></div>
                                                            <div className='block'>Installation: <span>{installation}</span><div className="clearfix"></div></div>
                                                        </div>
                                                        <div className='component-text'>
                                                            <div><b>BreatheEV Software</b></div>
                                                            <ul>
                                                                <li>$0 Annual Cost</li>
                                                                <li>Reservation-Enabled Charging</li>
                                                                <li>Utilization Maximizing Features</li>
                                                                <li>Live Status Dashboard</li>
                                                                <li>Utilization and Uptime Reporting</li>
                                                                <li>Payment Integration</li>
                                                                <li>Multi-Site Management</li>
                                                                <li>User Verification</li>
                                                                <li>Guest Networks</li>
                                                                <li>Energy Management</li>
                                                            </ul>
                                                        </div>
                                                        <div className='inputs no-top-margin'>
                                                            <input placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} type='text' />
                                                            <input className={isValidEmail(email) || email === '' ? '' : 'error'} placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} type='text' />
                                                            <label className='error'>Enter a valid Email address</label>
                                                            <input className={isValidPhoneNumber(phone) || phone === '' ? '' : 'error'} placeholder='Phone Number' value={phone} onChange={(e) => setPhone(e.target.value)} type='text' />
                                                            <label className='error'>Enter a valid Phone Number</label>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                        {installation === 'Turnkey Charger Onboarding' ?
                                                            <>
                                                                <hr />
                                                                <div className='component-group'>
                                                                    <div className='component-text'>
                                                                        <b>Total Cost:</b>
                                                                        <div>$0 (software) +  installation cost</div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                            : undefined
                                                        }
                                                    </div>
                                                }
                                            ]} />

                                        }
                                    </>}

                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </main>
    );
};

export default Purchase;
