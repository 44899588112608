import React, { useState } from 'react';
import './FAQ.css';
import { FaPlus } from 'react-icons/fa6';
import { FiMinus } from "react-icons/fi";

interface QuestionViewProps {
  question: string;
  answer: string;
}

const QuestionView: React.FC<QuestionViewProps> = ({ question, answer }) => {

  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <div className={"row g-0  overflow-hidden flex-md-row h-md-250 position-relative" + (showAnswer && ' shadow-sm bg-white mb-4 ')}>
      <div className="col p-4 d-flex flex-column position-static">
        <div className="faq-question-view">
          <div onClick={() => { setShowAnswer(!showAnswer) }} className='question'>{showAnswer ? <FiMinus /> : <FaPlus />}{question}</div>
          {showAnswer && <div className='answer'>{answer}</div>}
        </div>
      </div>
    </div>
  );
};

export default QuestionView;
