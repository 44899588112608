import React, { useState } from 'react';
import './Footer.css'
import { FaLinkedinIn } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { RiSendPlane2Fill } from "react-icons/ri";
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import PaperAirplaneImage from '../../../Images/paper_airplane.svg';
import { Image } from 'react-bootstrap';
import { GoDotFill } from "react-icons/go";
import LegalDoc from './LegalDoc';
import Email from '../../../components/Email';


const Footer: React.FC = () => {
  const [legalDocVisible, setLegalDocVisible] = useState(false);
  const [legalDocType, setLegalDocType] = useState('');
  const [email, setEmail] = useState<string>('');
  const [inprogress, setInprogress] = useState<boolean>(false);

  const sendEmail = async () => {
    try {
      setInprogress(true);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email == '') {
        throw new Error('Enter an email address');
      }
      else if (!emailRegex.test(email)) {
        throw new Error('Invalid email format');
      }
      const emailSender = new Email();

      // const success = await emailSender.makeSubscription(email);
      const success = await emailSender.makeEmail(email, email,'New Subscription', [
        { head: 'Email', value: email },
      ]);

      if (success) {
        setEmail('');
        toast.success('You have successfully subscribed!', { position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.error(`Subscription  Failed.`, { position: toast.POSITION.TOP_RIGHT });
      }

    } catch (error) {
      setInprogress(false);
      toast.error(`${error}`, { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setInprogress(false);
    }
  };

  return (
    <footer id="footer">

      <div className="container newsletter">

        <div className="row align-items-center">
          <div className="col-md-5 ps-lg-0 pt-5 pt-md-0 text-start"  >
            <Image className="img-fluid" src={PaperAirplaneImage} alt="" />
          </div>
          <div className="col-md-7 py-5 newsletter-text "  >
            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">Newsletter</div>
            <h2 className="text-white mb-4">Subscribe to receive news and updates.</h2>
            <div className="position-relative w-100 mt-3 mb-2">
              <input disabled={inprogress ? true : false} onChange={(e) => setEmail(e.target.value)} value={email} className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Enter Your Email" />
              <button disabled={inprogress ? true : false} onClick={inprogress ? undefined : sendEmail} value={email} type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2">{inprogress ? <Spinner className='send-email-progress' /> : <RiSendPlane2Fill className='fs-4 send-email' />}</button>
            </div>
            {/* <small className="text-white-50">Diam sed sed dolor stet amet eirmod</small> */}
          </div>
        </div>

      </div>


      <div className="container py-4 align-items-lg-center copyright-center">
        <div className="col-sm-auto">
          <div className="copyright">
            <div className='col-sm-auto'>© {(new Date().getFullYear())} by <strong><span>BreatheEV</span></strong>. All Rights Reserved.</div>
            <div className='col-sm-auto'>
              <a className="text-white-50 text-right mx-1" href='#' onClick={() => { setLegalDocType('terms-of-service'); setLegalDocVisible(true); }}> <GoDotFill style={{ marginTop: '-3px' }} /> Terms of Use</a>
              <a className="text-white-50 text-right mx-1" href='#' onClick={() => { setLegalDocType('privacy-policy'); setLegalDocVisible(true); }}> <GoDotFill style={{ marginTop: '-3px' }} /> Privacy Notice</a>
            </div>
            <LegalDoc type={legalDocType} modalVisible={legalDocVisible} closeModal={() => { setLegalDocVisible(false); }} />
          </div>
        </div>
        <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a target='_blank' href="https://x.com/breathe_ev?s=21&t=t1vMXHcODWcYZq1MZrWDng" className="twitter"><BsTwitterX /></a>
          <a target='_blank' href="https://www.linkedin.com/company/breathe-ev/" className="linkedin"><FaLinkedinIn /></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
