import React, { useEffect, useState } from 'react';
import './Post.css';
import { Link, useParams } from 'react-router-dom';
import  { findArticleBySlug } from '../articles';
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoShare } from "react-icons/io5";


const Post: React.FC = () => {

  const { slug } = useParams();

  const blogPost = findArticleBySlug(slug ? slug : '');

  useEffect(() => {
    document.title = `${blogPost?.title} - Blog | BreatheEV`;
  }, [slug]);

  const scrollToTop = () => {
    setTimeout(()=> {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
  };


  const [isCopying, setIsCopying] = useState(false);
  const handleShareClick = async() => {
    setIsCopying(true);

    const currentURL = window.location.href;
    await navigator.clipboard.writeText(currentURL);

    setTimeout(() => {
      setIsCopying(false);
    }, 2000);
  };

  return (
    <main id="main">
      <section id="post-main">
        <div className="container text-center">
          <h1>Blog</h1>
        </div>
      </section>
  
      <section id="post-row" className="container mt-3 bg-light mb-5">  
        <div className="container-fluid">
          <div className='breadcrumbs'><Link onClick={() => { scrollToTop(); }} to={'/blog'}>Blog</Link>  <FaLongArrowAltRight /> {blogPost?.title}</div>
          <div className="row">
            <h3 className='post-title'>{blogPost?.title}</h3>
            <div className='post-details'>
              <p>Author : {blogPost?.author}</p>
              <p>Published On : {blogPost?.date}</p>
              <button className='share-button'  onClick={handleShareClick} type='button'>{isCopying ? 'Copied!' : 'Share'}<IoShare /></button>
            </div>
            <div className="post-content" dangerouslySetInnerHTML={{__html: blogPost?.data ? blogPost?.data :''}}></div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Post;
