import { Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import './Footer.css'


const LegalDoc = ({ modalVisible, closeModal, type }) => {
    const handleCancel = () => {
        closeModal();
    };

    return (
        <Modal show={modalVisible} onHide={handleCancel} size="xl">
            <Modal.Header closeButton className='LegalDoc'>
                <Modal.Title>
                    {type == 'privacy-policy' ?
                        <h2><strong>BreatheEV Privacy Notice</strong></h2>
                        :
                        <h2><strong>BreatheEV - Terms of Use</strong></h2>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='LegalDocBody'>

                {
                    type == 'privacy-policy' ?
                        <>
                            {/* <h1 style={{ textAlign: 'center' }}><strong>BreatheEV Privacy Notice</strong></h1> */}
                            <p>Your privacy is important to us at BreatheEV. Our Privacy Notice is crafted to offer clarity regarding our data practices in a format that is straightforward and user-friendly. Our easy-to-navigate format ensures that you can quickly find the sections most relevant to you, making it simpler for you to understand our practices and your rights. We urge you to read the sections below to understand how BreatheEV collects, utilizes, shares, and protects your information, all with the aim of delivering a seamless EV charging and energy experience. For the purpose of this Notice, “app” refers to both the downloaded mobile application and the BreatheEV web app.</p>
                            <p><strong>Privacy from Day One</strong></p>
                            <p>At BreatheEV, we strongly value the importance of privacy. When you interact with BreatheEV, using either our app or our charging stations, specific data is generated. This data allows us to enhance your EV charging experience. Rest assured that from the very beginning of your engagement with our services we prioritize the security of your data.</p>
                            <p>BreatheEV’s charging stations are equipped with cutting edge technology that allow for optimized charging times. Our BreatheEV app and charging station software are designed with capabilities that give you the best possible charging experience. While these features require us to collect some data - such as location and charging patterns for functionality - we have implemented measures to ensure your privacy is protected during the processing of this data.</p>
                            <p>BreatheEV’s commitment to privacy extends across all our products and offerings. Whether you are using our existing solutions or exploring features on our platform, we strive to gather only the data that is necessary for enhancing your experience. This information is utilized for providing services, improving our products, and ensuring an experience tailored for you.</p>
                            <p><strong>Information We May Collect</strong></p>
                            <h3>When You Interact With Us Before Purchase</h3>
                            <p>When you visit our website, use our app, or contact us for customer support, we may collect various information from or about you, your devices, or from third parties. For instance:</p>
                            <p>Information from or about you:</p>
                            <div className="table-responsive">
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>Categories of Data</th>
                                            <th>Description</th>
                                            <th>Purpose and Legal Basis</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Contact Information</td>
                                            <td>Your name, email, phone number</td>
                                            <td>To communicate with you, process your requests, and provide products or services based on consent or our contract with you</td>
                                        </tr>
                                        <tr>
                                            <td>Communications or interactions</td>
                                            <td>Your requests for information, calls, emails</td>
                                            <td>To communicate with you, confirm eligibility, and provide products or services based on consent or our contract with you</td>
                                        </tr>
                                        <tr>
                                            <td>Network activity information</td>
                                            <td>Your device model, browser type, operating system, IP address</td>
                                            <td>Optimize website and app performance, based on either your consent, our contract with you and/or our legitimate interests in improving user experience and security</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p>We may also receive information about you from sources such as public databases, marketing partners, affiliates, social media platforms, business partners, government agencies, and other public entities.</p>
                            <h3>When You Are a BreatheEV User</h3>
                            <p>If you are a BreatheEV user, we may also collect other information from or about you, your charging sessions, app usage, or from third parties. We may collect such information either through the app or in person. You are a BreatheEV User when you sign into your account on a downloaded app on your mobile device, accepting the End User License Agreement and the Terms of Use.</p>
                            <p>BreatheEV Account: Users may create a BreatheEV Account on our app. You can access your BreatheEV Account to update the information from or about you at any time. The information contained in your account includes:</p>
                            <ul>
                                <li>Your booking details</li>
                                <li>Your user registration information</li>
                                <li>Payment method information</li>
                                <li>Usage data and preferences</li>
                                <li>Feedback and reports</li>
                            </ul>
                            <p>As a user, we may collect additional information from or about you:</p>
                            <div className="table-responsive">
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>Categories of Data</th>
                                            <th>Description</th>
                                            <th>Purpose and Legal Basis</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Booking Details</td>
                                            <td>Your charging slot reservations, location preferences</td>
                                            <td>To fulfill your request for products and services based on our contract with you</td>
                                        </tr>
                                        <tr>
                                            <td>Payment Information</td>
                                            <td>Your payment method, bank account number, credit card details</td>
                                            <td>To process your payment for the service used based on our contract with you</td>
                                        </tr>
                                        <tr>
                                            <td>Customer support activity</td>
                                            <td>Customer interactions, date, resolutions</td>
                                            <td>To provide customer service and technical support based on our contract with you and our legitimate interests regarding customer satisfaction</td>
                                        </tr>
                                        <tr>
                                            <td>Vehicle information</td>
                                            <td>Make, model, year, and other information on the vehicle</td>
                                            <td>To provide optimum charging services, as well as customer service and technical support based on your specific needs</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p>Information from or about your BreatheEV charging experience:</p>
                            <div className="table-responsive">
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>Categories of Data</th>
                                            <th>Description</th>
                                            <th>Purpose and Legal Basis</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Charging Data</td>
                                            <td>Charging station used, utilization, charge rate</td>
                                            <td>To provide you with services, improve charging network based on our legitimate interests</td>
                                        </tr>
                                        <tr>
                                            <td>Location Data</td>
                                            <td>Charging station locations, your location preferences</td>
                                            <td>To provide you with relevant charging options and improve our services based on our contract with you</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p>Information from or about your BreatheEV app usage</p>
                            <div className="table-responsive">
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>Categories of Data</th>
                                            <th>Description</th>
                                            <th>Purpose and Legal Basis</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>App Analytics</td>
                                            <td>Features used, session duration, errors encountered</td>
                                            <td>To ensure reliability of the app, and perform maintenance, based on our legitimate interests in improving user experience and the security and stability of our services</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p>Opting out of data collection: Connectivity and user experience are core parts of all BreatheEV services. By default, BreatheEV provides this seamless experience while protecting your privacy. However, if you no longer wish for us to collect data or any other data from your BreatheEV usage, please contact us to adjust your preferences. Please note, certain advanced features such as real-time charging availability, notifications, and interactivity with the app rely on such connectivity. Data collection essential to the functionality of our service may not be opted out of, unless otherwise required by law.</p>
                            <p><strong>How We May Use Your Information</strong></p>
                            <p>We may use the information we collect to:</p>
                            <ul>
                                <li>Communicate with you</li>
                                <li>Fulfill our services and bookings</li>
                                <li>Improve and enhance the development of our services</li>
                            </ul>
                            <p>These uses include contacting you to notify you of important updates related to our charging stations, to present services and offers tailored to you, or to respond to your inquiries and fulfill your requests for service information, BreatheEV updates, and other events.</p>
                            <h3>When You Interact With Us</h3>
                            <p>When you contact us for information or use our services, we may use the data collected for various purposes. Below is a non-exhaustive list of how we may use your personal data to deliver an optimal charging experience:</p>
                            <ul>
                                <li>Communicate with you<ul>
                                    <li>To respond to your inquiries and fulfill your requests</li>
                                    <li>To offer you information on promotions and to administer these activities</li>
                                    <li>To share administrative information with you, such as changes to our terms, conditions, and policies</li>
                                    <li>To advise you of important updates about our charging stations</li>
                                    <li>To conduct surveys and obtain your feedback</li>
                                </ul>
                                </li>
                                <li>Fulfill our services and bookings<ul>
                                    <li>To complete your booking and ensure the charging slot is available for you</li>
                                    <li>To process your payment for a service</li>
                                    <li>To monitor the efficiency of our charging stations and implement idle charges</li>
                                    <li>To understand and address issues or concerns with our services</li>
                                </ul>
                                </li>
                                <li>Improve and enhance the development of our services<ul>
                                    <li>To conduct research, develop and promote new services, and to improve or modify our existing services</li>
                                    <li>To analyze and improve the efficiency and availability of our charging stations</li>
                                    <li>For business purposes such as data analytics, audits, business planning, determining the effectiveness of campaigns, reporting, and forecasting</li>
                                </ul>
                                </li>
                                <li>Other circumstances<ul>
                                    <li>To fulfill contractual obligations with third parties, partners, and affiliates</li>
                                    <li>To comply with legal obligations, law enforcement, and other government requests</li>
                                    <li>To establish, exercise, or defend legal claims</li>
                                    <li>As otherwise permitted or directed by you</li>
                                </ul>
                                </li>
                            </ul>
                            <p>BreatheEV retains personal data as long as necessary to fulfill the purposes for which it was collected, including as described in this notice, or as required by law. The criteria for retention may include consideration of its use, our relationship with you, whether there is an existing contract for services, whether you have provided your consent, or if there is a legal requirement to retain certain data.</p>
                            <p>When we process personal data about you, we do so with your consent and/or as required to provide the services you use, operate our business, meet our contractual and legal obligations, protect the security of our systems and our customers, or fulfill other legitimate interests of BreatheEV. We do not share information that personally identifies you with third parties for their marketing purposes unless you opt-in to that sharing.</p>
                            <p>We may also use and disclose information about you if we believe in good faith that the law requires it for purposes of security, fulfilling our legal obligations, law enforcement, or other issues of public importance. We may share information about you, where there are legal grounds to do so, if we determine that disclosure is reasonably necessary to enforce our terms or protect our operations or customers. This could include providing information to governmental authorities. Additionally, in the event of a reorganization, merger, or sale, we may transfer any personal data we collect to the relevant third party.</p>
                            <p>Collection and Use of Non-Personal Data</p>
                            <p>Except as described in this Notice, BreatheEV may also collect, use, and share information that does not, on its own, personally identify you. Such information may be used for any purpose, including for example, for operational or research purposes, for industry analysis, to improve or modify our services, to better tailor our services to your needs, and where legally required.</p>
                            <p><strong>Sharing Your Information</strong></p>
                            <p>We may share information with:</p>
                            <ul>
                                <li>Our service providers, business partners, and affiliates.</li>
                                <li>Third parties you authorize.</li>
                                <li>Other third parties as required by law.</li>
                            </ul>
                            <p>We do not sell your personal data to anyone for marketing or advertising purposes. The table below illustrates a non-exhaustive list of who BreatheEV may share your personal data with, depending on your interactions with us:</p>
                            <p>Our service providers, business partners, and affiliates:</p>
                            <div className="table-responsive">
                                <table className='table table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>Categories of Recipients</th>
                                            <th>Description</th>
                                            <th>Purpose and Legal Basis</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Affiliates and subsidiaries</td>
                                            <td>Entities that are owned or controlled by BreatheEV, or where we have a significant ownership interest.</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>To assist, operate, enhance, and fulfill services on our behalf based on our legitimate interests.</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Service providers and business partners</td>
                                            <td>Companies we&#39;ve partnered with to expand our service range, such as EV networks, charger companies, and data analytics firms.</td>
                                            <td>To assist, operate, enhance, and fulfill services on our behalf based on our legitimate interests.</td>
                                        </tr>
                                        <tr>
                                            <td>Payment processors</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Companies we contract with to securely process your payment details.</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>To enable payment processing based on our contract with you, and fraud prevention in line with our legitimate interests.</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>EV networks and charger companies</td>
                                            <td>Companies who own and operate charging stations.</td>
                                            <td>Collaborations to enhance our service offerings.</td>
                                        </tr>
                                        <tr>
                                            <td>Utility companies</td>
                                            <td>Utilities and power providers that ensure reliable operation of chargers.</td>
                                            <td>To ensure seamless charging experiences and integration with the grid.</td>
                                        </tr>
                                        <tr>
                                            <td>Marketing partners</td>
                                            <td>Entities we collaborate with for promotional activities.</td>
                                            <td>To facilitate collaborations and partnerships based on your consent or our contract with you.</td>
                                        </tr>
                                        <tr>
                                            <td>Law enforcement and government authorities</td>
                                            <td>Disclosure of information as required by law or when otherwise appropriate</td>
                                            <td>To comply with applicable laws based on our legal obligation or legitimate interests.</td>
                                        </tr>
                                        <tr>
                                            <td>Regulatory bodies</td>
                                            <td>Entities that oversee EV charging standards and practices.</td>
                                            <td>To ensure adherence to industry standards and best practices.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <p>The data we collect may be shared between BreatheEV and our subsidiaries, affiliates, third-party service providers, and others when necessary to perform services on our or on your behalf. For example, companies we partner with to expand our service range may need access to data to provide those functions. In such cases, these companies must abide by our privacy and data protection requirements and are not allowed to use personal data they receive from us for any other purpose.</p>
                            <p>In addition, we may share information with:</p>
                            <ul>
                                <li>Service providers and business partners to provide services such as data analysis, customer service, marketing, and other similar services.</li>
                                <li>Subsidiaries and affiliates to develop new and improve existing services, perform data analysis, and reporting.</li>
                                <li>Other third-party business partners to the extent that they are involved in collaborations or partnerships related to our services.</li>
                            </ul>
                            <p>Third parties when required by law or other circumstances, such as:</p>
                            <ul>
                                <li>To comply with a legal obligation (such as subpoenas or other court orders); in response to a lawful request by governmental authorities conducting an investigation; to verify or enforce our policies and procedures; to respond to an emergency; or to protect the rights, property, safety, or security of our services, BreatheEV, third parties, or the public.</li>
                                <li>In the event of a reorganization, merger, or sale, we may transfer any personal data we collect to the relevant third party.</li>
                            </ul>
                            <p><strong>Choice and Transparency</strong></p>
                            <p>Your data belongs to you and at BreatheEV we try to  ensure you have as much control over it as possible. We provide various user options for managing their data and preferences. If you ever find discrepancies in the data we hold about you or wish to update it, we have procedures for updating or correcting personal information. We strive to ensure that the data we hold is accurate and up-to-date.</p>
                            <p>We understand that not everyone might be comfortable with all data-sharing practices. That is why we offer opt-out options for some data sharing, ensuring you are informed about potential impacts on service functionality.</p>
                            <p>If you have questions or concerns about how we handle your data, we are here to help. We provide clear contact details for privacy-related inquiries and a straightforward process for submitting complaints or concerns about our privacy practices.</p>
                            <p><strong>Legal and Regulatory Compliance</strong></p>
                            <p>We are committed to adhering to all legal and regulatory standards. This commitment ensures that our services not only provide value but also operate within the confines of the law. One of our primary obligations is compliance with legal requirements for per-hour charging, ensuring that our pricing structures are transparent and fair.</p>
                            <p>We believe that you should be fully aware of your rights. Our platform provides information on user rights and remedies under applicable privacy laws. This ensures that you are not only protected by our internal policies but also by external legal frameworks. We continuously monitor changes in the legal landscape and adjust our practices accordingly. This proactive approach ensures that BreatheEV remains compliant at all times.</p>
                            <p>Beyond simply adhering to existing laws, we strive to set a benchmark in ethical data practices. Our legal and regulatory compliance efforts are a testament to our dedication to operating with integrity and transparency.</p>
                            <p><strong>Security Measures and Protection of Personal Data</strong></p>
                            <p>At BreatheEV, we use a variety of security measures to protect your data. These measures are intended to prevent unauthorized access, use, or disclosure of your information. To that end, we have invested in both technological and organizational safeguards. We are committed to protecting user information from unauthorized access or disclosure, and we continue to evaluate and update our security protocols.</p>
                            <p>We understand the evolving nature of digital threats. As such, we employ security technologies and best practices to ensure that your data remains protected. This includes the use of secure servers, secure storage, and data encryption. By encrypting your data, we ensure that even if malicious actors were to access it, they would not be able to decipher its contents. Secure storage practices ensure that your data is stored in environments that are safeguarded against breaches.</p>
                            <p>We monitor and update our data protection measures in response to the evolving digital landscape. This proactive approach ensures that BreatheEV remains at the forefront of data protection practices.</p>
                            <p><strong>Advanced Features</strong></p>
                            <p>BreatheEV offers a range of advanced features designed to enhance your experience. While these features provide added value, they might also require additional data collection. We ensure that every feature that requires data collection is transparently communicated to you.</p>
                            <p>Collaboration is key to innovation. Sometimes, we might share data with partners to improve a feature. However, any information on data sharing with partners for feature improvement is communicated transparently, ensuring you are always in the loop.</p>
                            <p>Our advanced features are designed with user benefit in mind. Whether it is a new way to book charging slots or a feature that provides insights into your charging habits, we ensure that data collection is always justified by tangible user benefits.</p>
                            <p><strong>Charging History</strong></p>
                            <p>To provide a seamless charging experience, BreatheEV collects details on your charging history. This includes data on when, where, and how often you charge your vehicle. Details on charging history help us understand user patterns and optimize our services accordingly.</p>
                            <p>Your charging data is not just used for service optimization. We also use this data for network improvement and government incentive programs. By understanding where and when charging is most needed, we can expand our network to serve you better.</p>
                            <p><strong>Diagnostic Data</strong></p>
                            <p>To make sure that BreatheEV&#39;s services run smoothly, we collect diagnostic data. This includes logs, error reports, and other technical data. The use of diagnostic logs for product improvement and troubleshooting helps us quickly address any issues and enhance our platform&#39;s reliability.</p>
                            <p>We are transparent about the types of diagnostic data collected. We aim to ensure that you are always informed about the data we collect and why we collect it. Diagnostic data is crucial for maintaining the high standards of service that BreatheEV is known for. By analyzing this data, we can proactively address potential issues, ensuring that you always have a seamless experience.</p>
                            <p>We handle diagnostic data with the same care and commitment to privacy as any other data. Our goal is to use this data to serve you better, always prioritizing your privacy and security.</p>
                            <p><strong>BreatheEV Mobile App Information</strong></p>
                            <p>The BreatheEV app serves as a user-friendly tool for accessing our services. It offers a range of features and functionalities tailored to meet your EV charging needs. Our team consistently enhances the app to ensure it keeps up with our users’ changing needs.</p>
                            <p>Similar to any platform, the BreatheEV app gathers data to operate smoothly. The purpose behind collecting this data is to enhance the app&#39;s functionality and improve the user experience.</p>
                            <p>Transparency is important to us, especially when it comes to data collection through our app. We are committed to keeping you informed about the types of data collected, how they are utilized, and the advantages they provide.</p>
                            <p><strong>California Privacy Disclosure</strong></p>
                            <p>California residents are entitled to knowledge, access, and deletion rights regarding their personal data under the California Consumer Privacy Act (CCPA). Additionally, they have the right to opt out of the sale of their personal data by businesses and the right not to face discrimination for exercising any of their privacy rights under California law. BreatheEV does not sell your personal data to anyone for any purpose and does not discriminate based on any privacy rights requests.</p>
                            <p>BreatheEV is transparent about our data practices, as detailed in this Privacy Notice. This encompasses the types of personal data we collect, the sources from which it is derived, and the purposes for its use. It also includes information on whether BreatheEV discloses that personal data and, if so, the categories of third parties to whom it is disclosed. California residents who have utilized BreatheEV&#39;s products or services can exercise their rights directly (or through an authorized agent) by signing in to their BreatheEV Account and submitting a data privacy request. If you are a California resident without a BreatheEV Account and wish to exercise your privacy rights, you can also reach out by:</p>
                            <p>Emailing us at <a href="mailto:info@breathe-ev.com">info@breathe-ev.com</a></p>
                            <p>Calling us at (844) 252-2083</p>
                            <p>Writing to us at BreatheEV Inc, Attn: Legal – Privacy, 140 Scott Drive | Menlo Park, CA 94025.</p>
                            <p>For those without a BreatheEV account, we may request specific information deemed necessary to verify your identity for security purposes and to prevent fraudulent activities. This could include your name, contact details, and other information related to your interactions or relationship with BreatheEV. The exact details required may vary based on the nature of your request, ensuring both your security and the protection of privacy rights. If you opt for the deletion of your personal data, be aware that we will render certain personal data about you permanently unrecoverable while also de-identifying other data.</p>
                            <p><strong>International Transfers</strong></p>
                            <p>BreatheEV is a global entity, operating across various international regions. By utilizing our services or providing your personal data, you acknowledge that your information may be transferred to BreatheEV facilities, subsidiaries, business partners, or contractors in countries other than where it was initially collected. This includes countries like the United States and others where we have operations or collaborate with service providers. We ensure compliance with international data transfer laws, guaranteeing that your data remains protected as outlined in this Notice, regardless of its location.</p>
                            <p>We base our data collection, usage, and processing on several legal grounds, including: your explicit consent; the necessity of the data for our service agreement with you; legal obligations; protection of vital interests; or a legitimate interest that doesn&#39;t infringe upon your rights. These legitimate interests encompass BreatheEV&#39;s aim to enhance its services, ensure safety, defend against misconduct, and address customer feedback. When necessary, we will clarify why we require specific data from you and the implications of not providing it.</p>
                            <p><strong>Updates to this Notice</strong></p>
                            <p>This Notice outlines the fundamental details regarding your personal data relationship with BreatheEV. As we evolve, we might introduce new capabilities, features, or offer supplementary services which may necessitate significant modifications to this Notice. When such changes occur, we&#39;ll update the date of this Notice on our website to reflect the latest revisions. On certain occasions, we might also provide you with additional notifications (like an email) to offer more details about the updates. Unless specified differently when we launch these new or enhanced services, they will fall under this Notice and become effective once we publish it on our website.</p>
                            <p><strong>Privacy Questions</strong></p>
                            <p>For privacy questions or comments, to opt out from certain services, or to submit a data privacy request, please contact us by:</p>
                            <p>Emailing us at <a href="mailto:info@breathe-ev.com">info@breathe-ev.com</a></p>
                            <p>Calling us at (844) 252-2083</p>
                            <p>Writing to us at BreatheEV Inc, Attn: Legal – Privacy, 140 Scott Drive | Menlo Park, CA 94025.</p>
                        </>
                        :
                        <>
                            {/* <h1 style={{ textAlign: 'center' }}><strong>BreatheEV - Terms of Use</strong></h1> */}
                            <p>Welcome to BreatheEV, your trusted partner in the electric vehicle charging ecosystem. As the electric vehicle industry continues to evolve, we are committed to offering a seamless, efficient, and user-friendly experience for all EV owners. Our services designed to cater to the diverse needs of our growing community. This document outlines the terms of use that govern our payment processes, charging services, and your interactions with our platform. By engaging with BreatheEV, you are entering into a binding agreement, ensuring that you understand and comply with the stipulations presented herein. Any reference to the BreatheEV app includes both the downloaded mobile app as well as the BreatheEV web app.</p>
                            <p>Our primary goal is to ensure transparency, fairness, and clarity in all our offerings. The terms detailed below encompass your obligations regarding payments, the specifics of our charging services, and the standards we uphold for our infrastructure. We urge you to read through these terms carefully, as they form the foundation of our relationship with you. By accessing and using our services, you signify your acceptance of these terms, the End User License Agreement, the Privacy Notice, and your commitment to adhere to them. As the electric vehicle landscape continues to change, so might our terms, and we pledge to keep you informed every step of the way.</p>
                            <p><strong>Payment Terms</strong></p>
                            <p>The Payment Terms presented herein are designed to provide a comprehensive understanding of your obligations regarding payments for both current and future services and products offered by BreatheEV. Additionally, these terms encompass any additional charges or fees that might be imposed in relation to the services and products. Throughout this document, references such as &#39;we&#39;, &#39;our&#39;, and &#39;us&#39; pertain specifically to BreatheEV, the entity that offers electric vehicle charging services across various regions.</p>
                            <p>Accessing our specialized services, which encompass a range of offerings from reserving charging slots to initiating one-time payments, setting up recurring payments, and subscribing to our premium offerings, requires your explicit consent. By engaging with our platform and services, you are granting BreatheEV the unequivocal authority to securely store, maintain, and, when necessary, retrieve funds from your chosen payment method, all in strict accordance with the stipulations laid out in these Payment Terms.</p>
                            <p>In our commitment to ensuring that our services evolve to meet the changing needs of our users and the industry, BreatheEV retains the right to periodically review, amend, and update these terms. We assure our users that any modifications made will be communicated promptly (within 5 business days) and will become effective immediately once they are posted on our official page.</p>
                            <p>When you decide to integrate a payment method into your BreatheEV Account, be it a credit card, debit card, or any other form of electronic payment, specific data is transmitted. This data, which includes but is not limited to your device&#39;s location, its unique ID, and card details, is processed through our trusted third-party payment processor, Stripe.</p>
                            <p>For a seamless and uninterrupted experience with our services, it is essential for users to maintain at least one active, valid payment method within their BreatheEV Account. Engaging with our services, especially those that involve financial transactions, might lead to charges being levied on your account. These charges could encompass service fees, additional costs, and taxes mandated by prevailing local, state, or federal law.</p>
                            <p>The accuracy and currency of your payment information are crucial for smooth transactions. Any discrepancies in your account, such as an expired payment method, outdated billing address, or incorrect card details, might impede your ability to access or utilize our services, including but not limited to reserving charging slots, making payments, or availing of special offers.</p>
                            <p>Certain services offered by BreatheEV come with the added convenience of automatic or recurring payments. Such services will result in your stored payment method being charged at regular intervals, typically coinciding with the renewal of the service. However, users retain full control over these automatic deductions and have the option to deactivate or modify this automatic renewal, provided such changes are made at least twenty-four (24) hours before the end of the current billing cycle.</p>
                            <p>BreatheEV&#39;s user interface, specifically the BreatheEV Account dashboard, is designed to be user-centric, intuitive, and transparent. At any given time, users can effortlessly navigate their account settings to manage payments, view charging history, update personal details, and access a range of other features. This ensures that users have complete control over their financial commitments and interactions with our services.</p>
                            <p>Financial transactions, while typically smooth, can sometimes encounter unforeseen issues. If, for some unforeseen reason, a fund recovery attempt from your stored payment method encounters issues or fails, you are granting BreatheEV the authority to recover the owed amount through alternative means or methods. In such rare scenarios, our customer support team might reach out to you to provide assistance, offer solutions, or request an alternative payment during subsequent service delivery.</p>
                            <p>Adherence to these Payment Terms is mandatory for all users of BreatheEV&#39;s services. Any lapses, discrepancies, or violations, such as unresolved payment dues, failure to update account details, or non-compliance with any stipulated terms, might lead to temporary or permanent restrictions on your access to our suite of services, pending resolution of the said issues.</p>
                            <p><strong>Vehicle Charging Terms</strong></p>
                            <p>BreatheEV is proud to offer a diverse range of charging solutions, meticulously tailored to cater to the varied needs of electric vehicle owners. These solutions may include reserved slots spread across various strategic locations, fast-charging options, and more. However, it is essential to note that while we strive to provide top-notch services, BreatheEV absolves itself from any liability pertaining to damages, losses, or inconveniences that might arise from using a charging station, navigating our payment processes, or any other interactions with our infrastructure, unless explicitly mandated by law.</p>
                            <p>Idle Fees: Our charging infrastructure, built with a vision of community service and accessibility, is designed for optimal utilization. Once your vehicle completes its charging, we strongly urge you to promptly relocate it, ensuring availability for fellow users and efficient use of our facilities. If vehicles remain parked post-charging, idle fees will be levied as a measure to ensure compliance, maintain availability, and discourage misuse of our infrastructure.</p>
                            <p>Recognizing that users might sometimes face unforeseen delays, we provide a brief five-minute window post-charging for vehicle relocation. If the vehicle is not moved within this stipulated period, idle fees apply. This policy, designed to ensure fairness and optimal utilization, is uniformly applicable to all vehicles, regardless of their make, model, or ownership status.</p>
                            <p>Idle fees, while straightforward, are calculated on a per-minute basis. The rates, determined based on various factors including location, demand, and operational costs, might undergo periodic revisions. Any such changes, in line with our commitment to transparency, are promptly (within 5 business days) communicated to our users via the BreatheEV app, email notifications, or other official channels.</p>
                            <p>In the event that you arrive at your reserved slot and find another vehicle occupying it, BreatheEV understands the inconvenience this might cause. To address such scenarios, we allow users to document the situation by taking clear photographs of the offending vehicle. Submitting this evidence can lead to a full or partial refund of your deposit, ensuring that you are not penalized for circumstances beyond your control.</p>
                            <p>BreatheEV places immense importance on maintaining the sanctity, cleanliness, and aesthetics of our charging spots. Littering, apart from being environmentally detrimental, tarnishes the experience for other users and is strictly prohibited. Any user found littering, disposing of waste inappropriately, or causing any form of environmental harm at a BreatheEV charging spot will be subjected to a fine, the amount of which will be determined based on the severity of the violation.</p>
                            <p>Reserving a slot with BreatheEV is a commitment that comes with specific time-bound obligations. Users are charged based on the duration of their reservation, and it is essential to note that users can only utilize the charger from the start to the end of their reserved slot. Delays, while sometimes unavoidable, do not translate to extended charging times or adjustments in fees. This policy ensures that all users have equal access to our services and that reservations are honored in a timely manner.</p>
                            <p>Punctuality is of the essence when utilizing BreatheEV&#39;s services. Post the start of your reservation, there is a brief 5-minute grace period. Once this period lapses, charges commence, irrespective of whether the charging process has been initiated, the vehicle is present, or any other factors. This policy is in place to ensure fairness, optimal utilization of our infrastructure, and to discourage last-minute cancellations or delays.</p>
                            <p>BreatheEV, in its commitment to transparency, fairness, and adaptability, reserves the right to modify the pay-per-use rate. However, any such changes will always be preceded by an official notice to our users, providing them ample time to review, understand, and if necessary, adjust their usage patterns.</p>
                            <p>Financial transactions are a critical component of our service. To ensure seamless, secure, and efficient payments, BreatheEV utilizes Stripe, a globally recognized third-party payment processor known for its robust security measures, user-friendly interface, and impeccable track record.</p>
                            <p>The pay-per-use rates, determined based on a myriad of factors including operational costs, demand, and market trends, are subject to periodic revisions. The most up-to-date information, reflecting any changes, promotions, or special offers, is always available on the BreatheEV app, ensuring that users are always informed and can make decisions based on the latest data.</p>
                            <p>To avail of BreatheEV&#39;s charging services, users are required to download the BreatheEV app, create a secure account, integrate a valid payment method, and agree to our comprehensive Payment Terms. This process, designed to be user-friendly and secure, ensures that users have a seamless experience from registration to charging.</p>
                            <p>The pay-per-use rate for each location is determined based on various factors and is communicated to users via the BreatheEV app, email notifications, or other official channels. This rate, while competitive, reflects the quality, convenience, and reliability of our services.</p>
                            <p>Charging at a specific BreatheEV station is an acknowledgment of the communicated price, terms, and conditions. For an in-depth understanding, clarifications, or any queries, users are directed to refer to our dedicated support page, contact our customer support team, or access the range of resources available on our official website.</p>
                        </>

                }

            </Modal.Body>
        </Modal>
    );
};

export default LegalDoc;
