import React, { FormEvent, useState } from 'react';
import styles from './CTAs.module.css';
import { Link } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Email from '../../../components/Email';

interface CTAsProps {
    getStartedBtn?: boolean;
    eBookBtn?: boolean;
    calculateEarningsBtn?: boolean;
    joinBreatheEVBtn?: boolean;
    removeLeftMargin?:boolean;
    propertyType?:string
}

const scrollToTop = () => {
    setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
};

interface FormData {
    name: string;
    email: string;
    phone: string;
    company: string;
    jobTitle: string;
}

const CTAs: React.FC<CTAsProps> = ({ getStartedBtn, eBookBtn, calculateEarningsBtn, joinBreatheEVBtn, removeLeftMargin, propertyType }) => {
    const [showModal, setShowModal] = useState(false);
    const [inprogress, setInprogress] = useState<boolean>(false);

    const handleDownloadEBook = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        phone: '',
        company: '',
        jobTitle: '',
    });


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setInprogress(true);
        if (!formData.name || !formData.email) {
            toast.error('Please fill in all fields', { position: toast.POSITION.TOP_RIGHT });
            setInprogress(false);
            return;
        }

        if (!isValidEmail(formData.email)) {
            toast.error('Please enter a valid email address', { position: toast.POSITION.TOP_RIGHT });
            setInprogress(false);
            return;
        }

        // const emailSender = new Email();
        // const success = await emailSender.makeConnection(formData);
        const success = true;

        if (success) {
            toast.success("Successful submission, we'll be in touch soon!", { position: toast.POSITION.TOP_RIGHT });
            // setShowModal(false);
        } else {
            toast.error(`Error to connect...`, { position: toast.POSITION.TOP_RIGHT });
        }

        setFormData({
            name: '',
            email: '',
            phone: '',
            company: '',
            jobTitle: '',
        });
        setInprogress(false);
    };


    return (
        <>
            <div className={`${styles['CTA-container']} mb-3 ${removeLeftMargin ? styles['remove-left-margin'] : ''}` }>
                <div className={`btn-group-vertical d-md-none ${styles['buttons-block']} ${styles['vertical']}`} role="group" aria-label="Basic outlined example">
                    {joinBreatheEVBtn && <Link to="/product/purchase" onClick={scrollToTop} className={`btn btn-outline-primary ${styles['btn']}`}>Join the BreatheEV Network</Link>}
                    {getStartedBtn && <Link to={propertyType ? "/product/purchase/" + propertyType : "/product/purchase"} onClick={scrollToTop} className={`btn btn-outline-primary ${styles['btn']}`}>Get Started with BreatheEV Today</Link>}
                    {eBookBtn && <button type="button" className={`btn btn-outline-primary ${styles['btn']}`} onClick={handleDownloadEBook}>Download our free e-Book</button>}
                    {calculateEarningsBtn && <Link to="/product/BERT" onClick={scrollToTop} className={`btn btn-outline-primary ${styles['btn']}`}>Calculate your Earnings</Link>}
                </div>
                <div className={`btn-group d-none d-md-block ${styles['buttons-block']} ${styles['horizontal']}`} role="group" aria-label="Basic outlined example">
                    {joinBreatheEVBtn && <Link to="/product/purchase" onClick={scrollToTop} className={`btn btn-outline-primary ${styles['btn']}`}>Join the BreatheEV Network</Link>}
                    {getStartedBtn && <Link to={propertyType ? "/product/purchase/" + propertyType : "/product/purchase"} onClick={scrollToTop} className={`btn btn-outline-primary ${styles['btn']}`}>Get Started with BreatheEV Today</Link>}
                    {eBookBtn && <button type="button" className={`btn btn-outline-primary ${styles['btn']}`} onClick={handleDownloadEBook}>Download our free e-Book</button>}
                    {calculateEarningsBtn && <Link to="/product/BERT" onClick={scrollToTop} className={`btn btn-outline-primary ${styles['btn']}`}>Calculate your Earnings</Link>}
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton className={styles['modal-top']}>
                    <Modal.Title>
                        Download our free e-Book
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='LegalDocBody'>

                    <div className="boxes d-flex flex-column align-items-stretch justify-content-center py-2 px-lg-2">
                        <form onSubmit={handleSubmit}>

                            <div className="row">
                                <div className="col-md-6 form-floating mb-3">
                                    <input type="text" value={formData.name} onChange={handleChange} required name="name" className="form-control" id="name" placeholder="Your Name" />
                                    <label className='marin-left-10px' htmlFor="name">Your Name</label>
                                </div>
                                <div className="col-md-6 form-floating mb-3">
                                    <input type="email" value={formData.email} onChange={handleChange} required className="form-control" name="email" id="email" placeholder="Your Email" />
                                    <label className='marin-left-10px' htmlFor="email">Your Email</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 form-floating mb-3">
                                    <input type="text" value={formData.company} onChange={handleChange} required name="company" className="form-control" id="company" placeholder="Company" />
                                    <label className='marin-left-10px' htmlFor="company">Company Name</label>
                                </div>
                                <div className="col-md-6 form-floating mb-3">
                                    <input type="text" value={formData.jobTitle} onChange={handleChange} required className="form-control" name="jobTitle" id="jobTitle" placeholder="Job Title" />
                                    <label className='marin-left-10px' htmlFor="companyRole">Job Title</label>
                                </div>
                            </div>

                            <div className="text-center mt-3">
                                <button className={styles['btn']} type="submit" disabled={inprogress ? true : false}>
                                    {inprogress ? <><Spinner animation="border" size="sm" /> Connecting...</> : <>Download our free e-Book </>}
                                </button>
                            </div>
                        </form>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default CTAs;
