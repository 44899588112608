import React, { useEffect, useState } from 'react';
import './CommercialEVCharging.css';
import InnovationImage from '../../Images/Innovation.svg';
import visionStatement from '../../Images/vision-statement-pana.svg';

import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';
import TabView from '../components/TabView';
import CTAs from '../components/CTAs';

import InnovationIcon from '../../Images/Icons/InnovationIcon.svg';
import SustainabilityIcon from '../../Images/Icons/sustainability.svg';
import ProfitabilityIcon from '../../Images/Icons/profitability.svg';
import EfficiencyIcon from '../../Images/Icons/efficiency.svg';

const CommercialEVCharging: React.FC = () => {

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
    };

    useEffect(() => {
        document.title = 'Commercial EV Charging | BreatheEV';
    }, []);



    const sliderContent = [
        {
            index: 0,
            title: "Innovation",
            icon: <Image src={InnovationIcon} />,
            text: " Leverage BreatheEV’s intelligent charging systems to provide reservation-based charging at your property, with options to limit use to private groups during work hours."
        },
        {
            index: 1,
            title: "Sustainability",
            icon: <Image src={SustainabilityIcon} />,
            text: "Propel your corporate social responsibility by integrating eco-friendly charging solutions that support environmental sustainability and can enhance your brand’s sustainability credentials."
        },
        {
            index: 2,
            title: "Profitability",
            icon: <Image src={ProfitabilityIcon} />,
            text: "Unlock new profit centers with our competitive revenue-sharing model, which transforms EV charging into a lucrative addition to your business."
        },
        {
            index: 3,
            title: "Efficiency",
            icon: <Image src={EfficiencyIcon} />,
            text: "Benefit from a turnkey solution where BreatheEV manages all aspects of your EV charging infrastructure, from installation to maintenance, ensuring minimal downtime and maximum performance with zero additional operational costs."
        }
    ];

    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <main id="main">
            <section id="commercial-ev-charging-main">
                <div className="container text-center">
                    <h1>Commercial EV Charging</h1>
                    <h2>Elevate Your Business and Enhance Customer Experience with BreatheEV Commercial EV Charging Solutions</h2>
                </div>
            </section>

            <section id="commercial-ev-charging" className="container commercial-ev-charging  bg-light">
                <div className="container-fluid">
                    <div className="row pt-5">
                        <CTAs getStartedBtn calculateEarningsBtn propertyType='commercial' />
                        <div className="col-xl-5 col-lg-6 d-flex justify-content-center align-items-stretch position-relative">
                            <Image src={visionStatement} className='commercial-ev-charging-image' />
                        </div>

                        <div className="col-xl-7 col-lg-6 boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                            <div className='mb-1'>
                                
                                <TabView sliderContent={sliderContent} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                                <p className='mt-3'>Equip your commercial spaces with BreatheEV’s reservable, and fully-managed commercial EV charging solutions to attract high-value clients and streamline operations without any operational costs.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </main>
    );
};

export default CommercialEVCharging;
