import React from 'react';
import styles from './PageNotFound.module.css';
import NotFoundImage from './NotFoundImage';
import { Link } from 'react-router-dom';

const PageNotFound: React.FC = () => {
  return (

      <div className="container">
        <div className={styles['not-found-main']}>
          <div className={styles['not-found-left']}>
            <h2 className={styles['head']}>404 - Page Not Found</h2>
            <h5 className={`${styles['text-center']} mb-3`}>Oops! The page you're looking for doesn't exist.</h5>
            <p>It might have been removed, had its name changed, or is temporarily unavailable.</p>
            <p>What can you do?</p>
            <ul>
              <li>Check the URL for any typos or errors.</li>
            </ul>
            <div className={styles['button-container']}>
              <Link className={styles['return-to-home']} to="/">Return to Homepage</Link>
            </div>
          </div>
          <div className={styles['not-found-right']}>
            <NotFoundImage />
          </div>
        </div>
      </div>

  );
};

export default PageNotFound;
