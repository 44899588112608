import React, { useEffect, useState } from 'react';
import './MultifamilyEVCharging.css';
import InnovationImage from '../../Images/Innovation.svg';
import visionStatement from '../../Images/chargers.svg';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';
import { Carousel, Tab, Tabs, } from 'react-bootstrap';
import { IoIosListBox } from "react-icons/io";
import TabView from '../components/TabView';
import CTAs from '../components/CTAs';

import InnovationIcon from '../../Images/Icons/InnovationIcon.svg';
import SustainabilityIcon from '../../Images/Icons/sustainability.svg';
import ProfitabilityIcon from '../../Images/Icons/profitability.svg';
import EfficiencyIcon from '../../Images/Icons/efficiency.svg';



const MultifamilyEVCharging: React.FC = () => {

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
    };

    useEffect(() => {
        document.title = 'Multifamily EV Charging | BreatheEV';
    }, []);


    const sliderContent = [
        {
            index: 0,
            title: "Innovation",
            icon: <Image src={InnovationIcon} />,
            text: "Embrace cutting-edge technology with BreatheEV’s smart charging solutions that adapt to future advancements and integrate seamlessly with modern lifestyles."
        },
        {
            index: 1,
            title: "Sustainability",
            icon: <Image src={SustainabilityIcon} />,
            text: "Enhance your property’s eco-friendly profile and qualify for green certifications by offering sustainable EV charging options that attract environmentally conscious tenants."
        },
        {
            index: 2,
            title: "Revenue",
            icon: <Image src={ProfitabilityIcon} />,
            text: "Generate a new revenue stream through our unique revenue-sharing model, turning EV charging stations into profitable assets for your property."
        },
        {
            index: 3,
            title: "Simplicity",
            icon: <Image src={EfficiencyIcon} />,
            text: "Enjoy hassle-free installation and management with BreatheEV taking care of all operational details, from setup to ongoing maintenance, all with no ongoing costs for you."
        }
    ];

    const [selectedTab, setSelectedTab] =useState(0);
    return (
        <main id="main">
            {/* <Image src={InnovationIcon} /> */}
            <section id="multifamily-ev-charging-main">
                <div className="container text-center">
                    <h1>Multifamily EV Charging</h1>
                    <h2>Maximize revenue generation and drive tenant satisfaction with BreatheEV’s multifamily EV charging solutions</h2>
                    {/* <Link to="/partner-with-us" onClick={scrollToTop} className="btn-get-started scrollto">Install Chargers</Link> */}
                </div>
            </section>

            <section id="multifamily-ev-charging" className="container multifamily-ev-charging bg-light">
                <div className="container-fluid pt-5">
                    <CTAs getStartedBtn calculateEarningsBtn propertyType="multi-family" />
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 d-flex justify-content-center align-items-stretch position-relative">
                            <Image src={visionStatement} className='multifamily-ev-charging-image' />
                        </div>

                        <div className="col-xl-7 col-lg-6 boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                            <div className='mb-1'>
                                {/* <CTAs getStartedBtn eBookBtn calculateEarningsBtn /> */}
                                <TabView sliderContent={sliderContent} selectedTab={selectedTab}  setSelectedTab={setSelectedTab} />
                                <p className='mt-3'>Install BreatheEV’s reservable, fully-managed charging stations to boost property appeal and generate revenue without any ongoing operational costs at your multifamily property.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

        </main>
    );
};

export default MultifamilyEVCharging;
