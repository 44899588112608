import React, { FormEvent, useEffect, useState } from 'react';
import './PartnerWithUs.css';
import Image from 'react-bootstrap/Image';
import LogoImage from '../Images/logo-black.png';
import Email from '../components/Email';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import BgImage from '../Images/WallBox_inst.jpg';


interface FormData {
    name: string;
    email: string;
    phone: string;
    company: string;
    companyRole: string;
    scheduledDate: string;
    address: string;
}

const PartnerWithUs: React.FC = () => {

    const [inprogress, setInprogress] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        phone: '',
        company: '',
        companyRole: '',
        scheduledDate: '',
        address: '',
    });

    useEffect(() => {
        document.title = 'Install Chargers | BreatheEV';
    }, []);

    const [screenSize, setScreenSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
    useEffect(() => {
        const handleResize = () => {
            setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setInprogress(true);
        if (!formData.name || !formData.email || !formData.address) {
            toast.error('Please fill in all fields', { position: toast.POSITION.TOP_RIGHT });
            setInprogress(false);
            return;
        }

        if (!isValidEmail(formData.email)) {
            toast.error('Please enter a valid email address', { position: toast.POSITION.TOP_RIGHT });
            setInprogress(false);
            return;
        }

        const emailSender = new Email();
        // const success = await emailSender.makeConnection(formData);
        const success = await emailSender.makeEmail(formData.name, formData.email, 'New Connection', [
            { head: 'Name', value: formData.name },
            { head: 'Email', value: formData.email },
            { head: 'Phone', value: formData.phone },
            { head: 'Company', value: formData.company },
            { head: 'Company Role', value: formData.companyRole },
            { head: 'Scheduled Date', value: formData.scheduledDate },
            { head: 'Address', value: formData.address },
        ]);
        if (success) {
            toast.success("Successful submission, we'll be in touch soon!", { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(`Error to connect...`, { position: toast.POSITION.TOP_RIGHT });
        }

        setFormData({
            name: '',
            email: '',
            phone: '',
            company: '',
            companyRole: '',
            scheduledDate: '',
            address: '',
        });
        setInprogress(false);
    };

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
    };

    const ContactForm = <div className={`col-xl-5 col-lg-6 boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-4 partner-form`}>
        <form onSubmit={handleSubmit}>
            <div className="col-md-12 text-center mb-5">
                <Image style={{ filter: 'brightness(0) invert(1)', width: ' 41%' }} className="img-fluid" src={LogoImage} alt="" />
            </div>
            <div className="row">
                <div className="col-md-6 form-floating mb-3">
                    <input type="text" value={formData.name} onChange={handleChange} required name="name" className="form-control" id="name" placeholder="Your Name" />
                    <label className='marin-left-10px required-label' htmlFor="name">Your Name</label>
                </div>
                <div className="col-md-6 form-floating mb-3">
                    <input type="email" value={formData.email} onChange={handleChange} required className={`${'form-control'} ${formData.email != '' && !isValidEmail(formData.email) ? 'is-invalid' : ''}`} name="email" id="email" placeholder="Your Email" />
                    <label className='marin-left-10px required-label' htmlFor="email">Your Email</label>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 form-floating mb-3">
                    <input type="text" value={formData.company} onChange={handleChange} required name="company" className="form-control" id="company" placeholder="Company" />
                    <label className='marin-left-10px required-label' htmlFor="company">Company</label>
                </div>
                <div className="col-md-6 form-floating mb-3">
                    <input type="text" value={formData.companyRole} onChange={handleChange} required className="form-control" name="companyRole" id="companyRole" placeholder="Company Role" />
                    <label className='marin-left-10px required-label' htmlFor="companyRole">Company Role</label>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 form-floating mb-3">
                    <input type="text" value={formData.phone} onChange={handleChange} className="form-control" name="phone" id="phone" placeholder="Phone" />
                    <label className='marin-left-10px' htmlFor="phone">Phone</label>
                </div>
                <div className="col-md-6 form-floating mb-3">
                    <input type="date"  
                        min={(() => {
                            const tomorrow = new Date();
                            tomorrow.setDate(tomorrow.getDate() + 1);
                            return tomorrow.toISOString().split('T')[0];
                        })()}
                        value={formData.scheduledDate} onChange={handleChange} className="form-control" name="scheduledDate" id="scheduledDate" placeholder="Schedule"
                    />
                    <label className='marin-left-10px' htmlFor="scheduledDate">Schedule a call</label>
                </div>
            </div>

            <div className="form-floating mb-3">
                <textarea style={{ minHeight: '100px' }} value={formData.address} onChange={handleChange} required className="form-control" name="address" id="address" placeholder="Property Address"></textarea>
                <label className='required-label' htmlFor="address">Property Address</label>
            </div>

            <div className="text-center mt-3">
                <button className='contact-btn' type="submit" disabled={inprogress ? true : false}>
                    {inprogress ? <><Spinner animation="border" size="sm" /> Connecting...</> : <>Connect</>}
                </button>
            </div>
        </form>
    </div>;

    return (
        <main id="main">
            <section id="partner-with-us-main">
                <div className='banner-image'>
                    <img src={BgImage} />
                    <div className='content-holder'>
                        <div className="container">
                            <div className='content'>
                                <h1>OUR CUSTOMERS</h1>
                                <h2>Embrace the future with BreatheEV's cutting-edge EV charging solutions. Transform your property, attract more tenants and visitors, and enhance your property's value and NOI. Partner with us to lead the way in the commercial real estate market. </h2>
                                <h2>Don't just adapt - set the standard. Contact BreatheEV today and electrify your property's appeal.</h2>
                                <Link to="/" onClick={scrollToTop} className="btn-get-started scrollto">Learn more about BreatheEV</Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="partner" className="container partner bg-light">
                <div className="row">
                    {screenSize.width > 991 ? undefined : ContactForm}
                    <div className="col-xl-7 col-lg-6 boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                        <div className='mb-5'>
                            <h3>What we offer our Customers</h3>
                            <h4 className='col-sm-12 text-centre'>The Complete Solution</h4>
                            <p>Transform your property into a modern, eco-friendly community with BreatheEV. Our innovative EV charging solutions are more than just an amenity; they're a statement of your commitment to a sustainable partner. As a valued BreatheEV partner, your property will not only enjoy the benefits of cutting-edge charging technology but also attract a forward-thinking resident demographic. Embrace the opportunity to be a leader in environmental responsibility and resident satisfaction. Don't let your property fall behind in the rapidly evolving world of EV technology. Contact us today, and let's embark on a journey to revolutionize your residents' charging experience and elevate your property's appeal. Be a part of the change – be a part of BreatheEV.</p>
                        </div>
                    </div>
                    {screenSize.width > 991 ? ContactForm : undefined}
                </div>
            </section>

        </main>
    );
};

export default PartnerWithUs;
