import React, { useEffect, useState } from 'react';
import './FAQ.css';
import breatheEVFAQ from './breatheEVFAQ';
import { MdManageSearch } from "react-icons/md";

import QuestionView from './QuestionView';

const FAQ: React.FC = () => {
  useEffect(() => {
    document.title = 'FAQ | BreatheEV';
  }, []);

  const [filter, setFilter] = useState<string>('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const filteredFAQs = breatheEVFAQ.filter(
    (faq) =>
      faq.question.toLowerCase().includes(filter.toLowerCase()) ||
      faq.answer.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <main id="main">
      <section id="faq-main">
        <div className="container text-center">
          <h1>FAQ</h1>
        </div>
      </section>

      <section id="faq-row" className="container faq-row  mt-3 bg-light mb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">

              <div className="col-md-5 mx-auto mb-5">
                <div className="input-group">
                  <span className="input-group-append">
                    <MdManageSearch />
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 border rounded-pill"
                    id="filter"
                    value={filter}
                    onChange={handleFilterChange}
                    placeholder='Filter by Question or Answer'
                  />
                </div>
              </div>

            </div>
            {filteredFAQs.map((faq, index) => (
              <div key={'faq-' + index} className="col-md-12 faq">
                <QuestionView question={faq.question} answer={faq.answer} />
              </div>
            ))}
            {filteredFAQs.length === 0 ? <div className='no-results'>No Results</div> : undefined}
          </div>
        </div>
      </section>
    </main>
  );
};

export default FAQ;
