import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import './Layout.css';
import Header from './components/Header';
import Footer from './components/Footer';

const Layout: React.FC = () => {
  return (
    <main>
      <Header />
      <div className='main-container'><Outlet /></div>
      <Footer />
      {/* <div id="preloader"></div> */}
    </main>
  );
};

export default Layout;
