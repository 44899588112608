import emailjs from '@emailjs/browser';

const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID ?? '';
const TEMPLATE_ID_1 = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_1 ?? '';
const TEMPLATE_ID_2 = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_2 ?? '';
const PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY ?? '';

class Email {

    makeEmail = async (name: string, email: string,title: string, data: { head: string, value: string }[]): Promise<boolean> => {
        let data_values = '';
        if (data.length) {
            data_values += ``;
            data.forEach(item => {
                
                if(item.value!="") {
                    data_values += `<tr><td class="paragraph"><strong>${item.head}</strong><br>${item.value}</td></tr>`;
                }
            });
        }
        const userData = {
            connect_name: name,
            connect_email: email,
            connect_title: title,
            connect_data: data_values,
        };
        try {
            const result = await emailjs.send(SERVICE_ID, TEMPLATE_ID_1, userData, PUBLIC_KEY);
            return true;
        } catch (error) {
            return false;
        }
    };

    makeEmailToUser = async (name: string, email: string,title: string, data: { head: string, value: string }[]): Promise<boolean> => {
        let data_values = '';
        if (data.length) {
            data_values += ``;
            data.forEach(item => {
                
                if(item.value!="") {
                    data_values += `<tr><td class="paragraph"><strong>${item.head}</strong><br>${item.value}</td></tr>`;
                }
            });
        }
        const userData = {
            connect_name: name,
            connect_email: email,
            connect_title: title,
            connect_data: data_values,
            connect_user_data: data_values
        };
        try {
            const result = await emailjs.send(SERVICE_ID, TEMPLATE_ID_2, userData, PUBLIC_KEY);
            return true;
        } catch (error) {
            return false;
        }
    };
    
    

    // makeConnection = async (message: Record<string, any>): Promise<boolean> => {
    //     const userData = {
    //         connect_name: message.name,
    //         connect_email: message.email,
    //         connect_phone: message.phone,
    //         connect_address: message.address
    //     };
    //     try {
    //         const result = await emailjs.send(SERVICE_ID, TEMPLATE_ID_1, userData, PUBLIC_KEY);
    //         return true;
    //     } catch (error) {
    //         return false;
    //     }
    // };

    // makeSubscription = async (email_id: String): Promise<boolean> => {
    //     const userData = {
    //         subscription_email: email_id,
    //     };
    //     try {
    //         const result = await emailjs.send(SERVICE_ID, TEMPLATE_ID_2, userData, PUBLIC_KEY);
    //         console.log(result);
    //         return true;
    //     } catch (error) {
    //         return false;
    //     }
    // };

}

export default Email;
