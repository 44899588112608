import React, { useState } from 'react';
import styles from './NavigationPage.module.css';

interface ComponentObject {
    component: React.ReactNode;
    canGoNext: boolean;
}

interface NavigationPageProps {
    head?: string;
    submitButtonLabel?: string;
    components: ComponentObject[];
    isSaving?: boolean;
    handleSave?: () => void;
    navigateToBegin?: () => void;
}

const scrollToTop = () => {
    setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
};

const NavigationPage: React.FC<NavigationPageProps> = ({ head, components, isSaving, handleSave, submitButtonLabel, navigateToBegin }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNext = () => {
        let nextIndex = currentIndex + 1;
        while (nextIndex < components.length && components[nextIndex].component === null) {
            nextIndex++;
        }
        setCurrentIndex(Math.min(nextIndex, components.length - 1));
        scrollToTop();
    };

    const goToPrevious = () => {
        if(currentIndex === 0) {
            navigateToBegin && navigateToBegin();
        }
        let prevIndex = currentIndex - 1;
        while (prevIndex >= 0 && components[prevIndex].component === null) {
            prevIndex--;
        }
        setCurrentIndex(Math.max(prevIndex, 0));
        scrollToTop();
    };

    return (
        <div className={styles.navigationPage}>
            <div className={styles.mainHead}>{head}</div>
            <div className={styles.componentContainer}>
                {components[currentIndex].component == null ? 'null' : components[currentIndex].component}
            </div>

            {!(currentIndex === components.length - 1) ?
                <div className={styles.navigationButtons}>
                    <button  disabled={navigateToBegin ? false : (currentIndex === 0 || (currentIndex > 0 && components[currentIndex - 1] === null))} onClick={goToPrevious}>Previous</button>
                    <button onClick={goToNext} disabled={!components[currentIndex].canGoNext}>Next</button>
                </div>
                :
                <div className={styles.navigationButtons}>
                    <button onClick={goToPrevious} disabled={(currentIndex === 0 || (currentIndex > 0 && components[currentIndex - 1] === null)) || isSaving}>Previous</button>
                    <button disabled={(!components[currentIndex].canGoNext) || isSaving} onClick={handleSave}>
                        {isSaving ? <div className={`spinner-border spinner-border-sm ${styles['spinner']}`} role="status"></div> : undefined}
                        {submitButtonLabel ? submitButtonLabel  :'Save quote'}
                    </button>
                </div>
            }
        </div>
    );
};

export default NavigationPage;
