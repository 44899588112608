import React, { useEffect } from 'react';
import './OCPPManagementSoftware.css';
import CTAs from '../components/CTAs';
import Image from 'react-bootstrap/Image';
import SoftwareImage from '../../Images/lap-and-phone.png';

const OCPPManagementSoftware: React.FC = () => {

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
    };

    useEffect(() => {
        document.title = 'BreatheEV Software | BreatheEV';
    }, []);

    return (
        <main id="main">
            <section id="ocpp-management-software-main">
                <div className="container text-center">
                    <h1>BreatheEV Management Software </h1>
                    <h2>Already have chargers? Let BreatheEV add new features, lower costs and increase revenue generation</h2>
                </div>
            </section>

            <section id="OCPPManagementSoftware" className="container ocpp-management-software  bg-light">
                <div className="container-fluid">

                    <div className="row">
                        <CTAs joinBreatheEVBtn calculateEarningsBtn />
                        <div className="col-xl-7 col-lg-6 boxes  flex-column align-items-stretch justify-content-center py-lg-5 px-lg-5">
                            <div className='mb-1'>
                               
                                
                                <div className='clearfix'/>
                                <div className='text-center'>
                                    <Image className='w-75' src={SoftwareImage} />
                                </div>
                                <p>BreatheEV’s management software allows you to connect to our network while we handle pricing, billing, user management, network connectivity and more, all while introducing attractive features.</p>
                                <h3>Pricing</h3>
                                {/* <hr /> */}
                                <p>Our management solution is priced so that we win when you win. That’s why our software costs $0 to layer into your existing OCPP compliant system.</p>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-6 boxes flex-column align-items-stretch justify-content-center py-lg-5 px-lg-5">
                            <div className='mb-1'>
                                <h3>Management Software</h3>
                                {/* <hr />s */}
                                <ul className='management-softwares'>
                                    <li>$0 annual cost</li> 
                                    <li>Reservation-Enabled Charging</li>
                                    <li>Utilization Maximizing Features</li>
                                    <li>Live Status Dashboard</li>
                                    <li>Utilization and Uptime Reporting</li>
                                    <li>Payment Integration</li>
                                    <li>Multi-Site Management</li>
                                    <li>User Verification</li>
                                    <li>Guest Networks</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </main>
    );
};

export default OCPPManagementSoftware;
