import React, { useEffect, useState } from 'react';
import './University.css';
import InnovationImage from '../../Images/Innovation.svg';
import visionStatement from '../../Images/car-with-charger.svg';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';
import { Carousel, Tab, Tabs, } from 'react-bootstrap';
import { IoIosListBox } from "react-icons/io";
import TabView from '../components/TabView';
import CTAs from '../components/CTAs';

import InnovationIcon from '../../Images/Icons/InnovationIcon.svg';
import SustainabilityIcon from '../../Images/Icons/sustainability.svg';
import ProfitabilityIcon from '../../Images/Icons/profitability.svg';
import EfficiencyIcon from '../../Images/Icons/efficiency.svg';



const University: React.FC = () => {

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
    };

    useEffect(() => {
        document.title = 'University EV Charging | BreatheEV';
    }, []);


    const sliderContent = [
        {
            index: 0,
            title: "Innovation",
            icon: <Image src={InnovationIcon} />,
            text: "Leverage BreatheEV's cutting-edge EV charger technology to enhance your campus infrastructure, demonstrating a commitment to clean technology that sets your university apart."
        },
        {
            index: 1,
            title: "Sustainability",
            icon: <Image src={SustainabilityIcon} />,
            text: "Promote a greener campus by integrating BreatheEV charging stations, reducing your carbon footprint and supporting your university's sustainability goals and initiatives."
        },
        {
            index: 2,
            title: "Accessibility",
            icon: <Image src={EfficiencyIcon} />,
            text: "Provide seamless EV charging access for students, faculty, and visitors with BreatheEV's user-friendly, reservation-based system, ensuring that everyone can charge their vehicles conveniently and efficiently."
        },
        {
            index: 3,
            title: "Cost Efficiency",
            icon: <Image src={ProfitabilityIcon} />,
            text: "Maximize your budget with BreatheEV's cost-effective EV charging solutions, which offer no operational expenses and the potential for funding through EV charging grants and subsidies initiatives, making it a smart investment for your campus."
        }

    ];

    const [selectedTab, setSelectedTab] =useState(0);
    return (
        <main id="main">
            {/* <Image src={InnovationIcon} /> */}
            <section id="university-main">
                <div className="container text-center">
                    <h1>University EV Charging</h1>
                    <h2>Maximize revenue generation and drive student satisfaction with BreatheEV's university EV charging solutions, offered by a leading provider of EV chargers.</h2>
                </div>
            </section>

            <section id="university" className="container university bg-light">
                <div className="container-fluid pt-5">
                    <CTAs getStartedBtn calculateEarningsBtn propertyType="university"/>
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 d-flex justify-content-center align-items-stretch position-relative">
                            <Image src={visionStatement} className='university-image' />
                        </div>

                        <div className="col-xl-7 col-lg-6 boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                            <div className='mb-1'>
                                <TabView sliderContent={sliderContent} selectedTab={selectedTab}  setSelectedTab={setSelectedTab} />
                                <p className='mt-3'>Install BreatheEV’s reservable, fully-managed charging stations to boost campus appeal and generate revenue without any ongoing operational costs at your university.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

        </main>
    );
};

export default University;
