interface FAQ {
    id: number;
    question: string;
    answer: string;
  }
  
  const breatheEVFAQ: FAQ[] = [
    {
      id: 1,
      question: "How do I locate a BreatheEV charging station near me?",
      answer:
        "A: Use our BreatheEV app or website to find nearby charging stations with real-time reservation availability updates."
    },
    {
      id: 2,
      question: "Can I reserve a charging spot in advance?",
      answer:
        "A: Yes, our platform allows you to reserve a charging spot ahead of time to ensure availability when you arrive."
    },
    {
      id: 3,
      question: "What types of electric vehicles are compatible with BreatheEV chargers?",
      answer:
        "A: BreatheEV chargers are designed to be compatible with all major EV models. Certain vehicles may require adapters to allow charging hardware to connect with vehicle charging ports."
    },
    {
      id: 4,
      question: "How do I start and stop a charging session?",
      answer:
        "A: Simply use the BreatheEV app to scan the QR code near the charger to sign up to start your first charge. After signing up, you will be able to use the web app to reserve a charging session. The web-app will guide you through starting and ending your session."
    },
    {
      id: 5,
      question: "What are the payment options for charging?",
      answer:
        "A: You can pay for your charging session using major credit cards to load your wallet, in the BreatheEV web-app."
    },
    {
      id: 6,
      question: "How much does it cost to charge my EV?",
      answer:
        "A: Charging costs vary by location and time. You can view specific rates directly in the BreatheEV app before starting your session."
    },
    {
      id: 7,
      question: "What do I do if I encounter an issue with a charger?",
      answer:
        "A: If you face any problems, please contact our 24/7 support team through the app or call our helpline. We're here to help!"
    },
    {
      id: 8,
      question: "How long does it take to charge my vehicle?",
      answer:
        "A: The time it takes to charge your vehicle depends on your car's battery size and the charging station's power output. Typically, a Level 2 charging station, like those provided by BreatheEV, can add about 30-55 miles of range per hour of charging, which varies by vehicle. For a full charge, most electric vehicles will require anywhere from 4 to 8 hours at a Level 2 charging station."
    },
    {
      id: 9,
      question: "Is it safe to leave my car charging while I am away from a BreatheEV station?",
      answer:
        "A: Yes, it's safe. However, we encourage users to follow any specific site rules regarding parking and charging times to ensure availability for all users."
    },
    {
      id: 10,
      question: "What happens once my charging session has ended?",
      answer:
        "Drivers should ensure they unplug their vehicles once a charging session has ended. Users who do not unplug and move their vehicle within the post-charging grace period will be charged an idle fee based on location. This ensures other users like you charge during their reserved time!"
    },
    {
      id: 11,
      question: "How can I track my charging history and expenses?",
      answer:
        "A: The View Bookings page in the BreatheEV web app allows you to view your complete charging history and expenses, making it easy to track and manage your usage."
    }
  ];
  
  export default breatheEVFAQ;
  