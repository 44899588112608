import React, { useEffect, useState } from 'react';
import './ROI.css';
import windmillImage from '../../Images/calculator-pana.svg';
import Image from 'react-bootstrap/Image';
import InputNumber from '../components/InputNumber';
import NavigationPage from '../components/NavigationPage';
import CTAs from '../components/CTAs';
import CalculatorImage from './CalculatorImage';
import { toast } from 'react-toastify';
import Email from '../../components/Email';

const ROI: React.FC = () => {

    useEffect(() => {
        document.title = 'BreatheEV Revenue Tool | BreatheEV';
    }, []);

    const [chargers, setChargers] = useState<number>(0);
    const [dailyUse, setDailyUse] = useState<number>(0);

    const [result, setResult] = useState<number>(0);

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [jobTitle, setJobTitle] = useState<string>('');
    const [phone, setPhone] = useState<string>('');

    const [onSaving, setOnSaving] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>(false);


    useEffect(() => {
        let calc = chargers * dailyUse * 365 * 11.2 * 0.35 * 0.6;
        setResult(calc);
    }, [chargers, dailyUse]);

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhoneNumber = (n: string): boolean => /^\+?1?\d{10,11}$/.test(n);

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
    };

    const handleSave = async () => {
        setOnSaving(true);
        const emailSender = new Email();
        const success = await emailSender.makeEmailToUser(name, email,'BreatheEV Revenue Tool', [

            { head: 'Number of chargers:', value: chargers?.toString() },
            { head: 'Average daily use per charger:', value: dailyUse?.toString() },
            { head: 'Estimated yearly total revenue:', value: result?.toFixed(2) },
            { head: 'Name', value: name },
            { head: 'Email', value: email },
            { head: 'Company', value: company },
            { head: 'Job Title', value: jobTitle },
            { head: 'Phone', value: phone },
        ]);
        if (success) {
            setIsFinished(true);
            setOnSaving(false);
            scrollToTop();
        } else {
            toast.error(`Error...`, { position: toast.POSITION.TOP_RIGHT });
        }
    }


    return (
        <main id="main">
            <section id="ROI-main">
                <div className="container text-center">
                    <h1>BreatheEV Revenue Tool (BERT)</h1>
                </div>
            </section>

            <section id="ROI" className="container ROI bg-light">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-xl-8 col-lg-6 d-flex justify-content-center align-items-stretch position-relative ROI-left-block">
                            <CalculatorImage value={Number(result.toFixed(0)).toLocaleString()} />
                        </div>

                        <div className="col-xl-4 col-lg-6 boxes d-flex flex-column align-items-stretch  py-5 px-lg-5 ROI-right-block">
                            <div className='mb-1'>
                                <h4 className='sub-head'>Calculate Your Returns with BreatheEV </h4>
                            </div>
                            {/* <CTAs joinBreatheEVBtn /> */}

                            {isFinished ?
                                <>
                                    <div>
                                        <div className='component-group'>
                                            <div className='component-text'>
                                                <div className='text-center'><b>Thank you, your email has been sent.</b></div>
                                            </div>
                                        </div>
                                        <CTAs joinBreatheEVBtn />
                                        <div className='component-text text-center'>BreatheEV: <b>info@breathe-ev.com</b></div>

                                    </div>
                                </>
                                :
                                <NavigationPage submitButtonLabel={'Email Results'} handleSave={() => { handleSave(); }} isSaving={onSaving} components={[
                                    {
                                        canGoNext: (chargers !== 0 && dailyUse !== 0),
                                        component: <>
                                            <div className='mb-1'>
                                                <div className='component-text'>Discover the revenue generation potential, and operational savings from our EV charging solutions with our easy-to-use calculator. Start planning today for a greener, more profitable tomorrow.</div>
                                                <div className='component-text'><b>See what electrifying your property looks like</b></div>
                                            </div>

                                            <div className='inputs no-top-margin'>
                                                <div className='component-group'>
                                                
                                                    <div className='component-label stick-bottom'>Number of chargers</div>
                                                    <InputNumber label='' value={chargers} minValue={1} maxValue={1000} onChange={(val) => { setChargers(val === '' ? 0 : val) }} />
                                                    <div className='component-label stick-top text-end'><div className='link-btn' onClick={() => { setChargers(4); }}>See our recommendation</div></div>
                                                </div>

                                                <div className='component-group'>
                                                    <div className='component-label stick-bottom'>Average daily use per charger</div>
                                                    <InputNumber label='Hours' value={dailyUse} minValue={0} maxValue={24} onChange={(val) => { setDailyUse(val === '' ? 0 : val) }} />
                                                </div>
                                            </div>

                                            <div className='component-text'>Estimated yearly total revenue: <b>${Number(result.toFixed(0)).toLocaleString()}</b></div>

                                            <div className='component-text'>
                                                <ul>
                                                    <li>Monthly Management Fee: $0</li>
                                                    <li>Hardware Costs: $0</li>
                                                    <li>BreatheEV’s $0 hardware and management helps maximize revenue generation while keeping your costs down.</li>

                                                </ul>
                                            </div>
                                        </>

                                    },
                                    {
                                        canGoNext: (name !== "" && (email !== "" && isValidEmail(email)) && company !== "" && jobTitle !== "" && (phone !== "" && isValidPhoneNumber(phone))),
                                        component: <>

                                            <div className='component-text'><b>See what electrifying your property looks like</b></div>
                                            <div className='component-text'>
                                                <div className='block'>Number of chargers: <span>{chargers} qty</span><div className="clearfix"></div></div>
                                                <div className='block'>Average daily use per charger: <span>{dailyUse} Hours</span><div className="clearfix"></div></div>
                                                <div className='block'>Estimated yearly total revenue: <span>${Number(result.toFixed(0)).toLocaleString()}</span><div className="clearfix"></div></div>
                                            </div>

                                            <div className='inputs no-top-margin'>
                                                <input placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} type='text' />
                                                <input className={isValidEmail(email) || email === '' ? '' : 'error'} placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} type='text' />
                                                <label className='error'>Enter a valid Email address</label>
                                                <input placeholder='Company name' value={company} onChange={(e) => setCompany(e.target.value)} type='text' />
                                                <input placeholder='Job Title' value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} type='text' />
                                                <input className={isValidPhoneNumber(phone) || phone === '' ? '' : 'error'} placeholder='Phone Number' value={phone} onChange={(e) => setPhone(e.target.value)} type='text' />
                                                <label className='error'>Enter a valid Phone Number</label>
                                            </div>


                                        </>
                                    }
                                ]}></NavigationPage>}



                        </div>
                    </div>

                </div>
            </section>

        </main>
    );
};

export default ROI;
