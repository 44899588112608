import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import Layout from './Layout/Layout';
import Home from './Home';
import About from './About';
import Recognition from './Recognition';
import Blog from './Blog';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import PartnerWithUs from './PartnerWithUs';
import Post from './Blog/Post';
import FAQ from './FAQ';
import MultifamilyEVCharging from './Product/MultifamilyEVCharging';
import CommercialEVCharging from './Product/CommercialEVCharging';
import OCPPManagementSoftware from './Product/OCPPManagementSoftware';
import Purchase from './Product/Purchase';
import ROI from './Product/ROI';
import { ToastContainer } from 'react-toastify';
import PageNotFound from './PageNotFound';
import University from './Product/University';

function App() {


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>

            <Route index path="/" element={<Home />} />
            <Route index path="/about" element={<About />} />
            <Route index path="/partner-with-us" element={<PartnerWithUs />} />
            <Route index path="/recognition" element={<Recognition />} />
            <Route index path="/blog" element={<Blog />} />
            <Route index path="/faq" element={<FAQ />} />
            <Route index path="/blog/:slug" element={<Post />} />

            <Route index path="/product/multifamily-ev-charging" element={<MultifamilyEVCharging />} />
            <Route index path="/product/university-ev-charging" element={<University />} />
            <Route index path="/product/commercial-ev-charging" element={<CommercialEVCharging />} />
            <Route index path="/product/ocpp-management-software" element={<OCPPManagementSoftware />} />
            <Route index path="/product/purchase" element={<Purchase />} />
            <Route index path="/product/purchase/:propertyType" element={<Purchase />} />
            <Route index path="/product/BERT" element={<ROI />} />

            <Route index path="*" element={<PageNotFound />} />

          </Route>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
