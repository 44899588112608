import React, { FormEvent, useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import styles from './VideoLoop.module.css';
import { toast } from 'react-toastify';
import Email from '../../../components/Email';


interface VideoModalProps {
    modalVisible: boolean;
    closeModal: () => void;
}

interface FormData {
    name: string;
    email: string;
    phone: string;
    company: string;
    companyRole: string;
}

const VideoModal: React.FC<VideoModalProps> = ({ modalVisible, closeModal }) => {
    const handleCancel = () => {
        closeModal();
    };
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const setVideoWatchRequest = (value: boolean): void => {
        localStorage.setItem('videoWatchRequest', JSON.stringify(value));
        setIsFormSubmitted(value);
    };

    const getVideoWatchRequest = (): boolean => {
        const value = localStorage.getItem('videoWatchRequest');
        return value !== null ? JSON.parse(value) as boolean : false;
    };

    useEffect(() => {
        console.log(getVideoWatchRequest());
        if (getVideoWatchRequest() === true) {
            setIsFormSubmitted(true);
        }
    }, []);

    const [inprogress, setInprogress] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        phone: '',
        company: '',
        companyRole: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setInprogress(true);
        if (!formData.name || !formData.email) {
            toast.error('Please fill in all fields', { position: toast.POSITION.TOP_RIGHT });
            setInprogress(false);
            return;
        }

        if (!isValidEmail(formData.email)) {
            toast.error('Please enter a valid email address', { position: toast.POSITION.TOP_RIGHT });
            setInprogress(false);
            return;
        }

        const emailSender = new Email();
        // const success = await emailSender.makeConnection(formData);
        const success = await emailSender.makeEmail(formData.name, formData.email, 'Video Watch Request', [
            { head: 'Name', value: formData.name },
            { head: 'Email', value: formData.email },
            { head: 'Phone', value: formData.phone },
            { head: 'Company', value: formData.company },
            { head: 'Company Role', value: formData.companyRole },
        ]);
        if (success) {
            toast.success("Successful submission!", { position: toast.POSITION.TOP_RIGHT });
            setVideoWatchRequest(true);
        } else {
            toast.error(`Error to connect...`, { position: toast.POSITION.TOP_RIGHT });
        }

        setFormData({
            name: '',
            email: '',
            phone: '',
            company: '',
            companyRole: ''
        });
        setInprogress(false);
    };

    return (
        <Modal centered aria-labelledby="contained-modal-title-vcenter" show={modalVisible} style={{borderRadius:'10px'}} onHide={handleCancel} size={isFormSubmitted ? "xl" : "lg"}>
            <Modal.Header closeButton className='LegalDoc'>
                <Modal.Title>
                    <h2><strong>Watch Video</strong></h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='LegalDocBody' style={isFormSubmitted ? { backgroundColor: 'black', padding:'0px' } : undefined}>

                {!isFormSubmitted ? <form onSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-md-6 form-floating mb-3">
                            <input type="text" value={formData.name} onChange={handleChange} required name="name" className="form-control" id="name" placeholder="Your Name" />
                            <label className='marin-left-10px required-label' htmlFor="name">Your Name</label>
                        </div>
                        <div className="col-md-6 form-floating mb-3">
                            <input type="email" value={formData.email} onChange={handleChange} required className={`${'form-control'} ${formData.email != '' && !isValidEmail(formData.email) ? 'is-invalid' : ''}`} name="email" id="email" placeholder="Your Email" />
                            <label className='marin-left-10px required-label' htmlFor="email">Email</label>
                        </div>

                        <div className="col-md-6 form-floating mb-3">
                            <input type="text" value={formData.company} onChange={handleChange} required name="company" className="form-control" id="company" placeholder="Company" />
                            <label className='marin-left-10px required-label' htmlFor="company">Company</label>
                        </div>
                        <div className="col-md-6 form-floating mb-3">
                            <input type="text" value={formData.companyRole} onChange={handleChange} required className="form-control" name="companyRole" id="companyRole" placeholder="Company Role" />
                            <label className='marin-left-10px required-label' htmlFor="companyRole">Company Role</label>
                        </div>

                    </div>

                    <div className='row justify-content-md-center'>
                        <div className="col-md-6 form-floating mb-3">
                            <input type="text" value={formData.phone} onChange={handleChange} className="form-control" name="phone" id="phone" placeholder="Phone" />
                            <label className='marin-left-10px' htmlFor="phone">Phone</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="text-center mt-3">
                            <button className={styles['contact-btn']} type="submit" disabled={inprogress ? true : false}>
                                {inprogress ? <><Spinner animation="border" size="sm" /> Connecting...</> : <>Submit and Watch Video</>}
                            </button>
                        </div>
                    </div>

                </form> : <div className={styles['video-frame']}>
                    <iframe
                        src="https://www.youtube.com/embed/Ubw_ivKegBI?rel=0&modestbranding=1&fs=1&controls=1&disablekb=1&autoplay=1"
                        title="BreatheEV Charging Solutions"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>}


            </Modal.Body>
        </Modal>
    );
};

export default VideoModal;
