import React from 'react';
import styles from './TabView.module.css';
import { IoIosListBox } from "react-icons/io";

interface SliderContentItem {
    index: number;
    title: string;
    text: string;
    icon: React.ReactNode;
}

interface TabViewProps {
    sliderContent: SliderContentItem[];
    selectedTab: number | null;
    setSelectedTab: (index: number) => void;
}

const TabView: React.FC<TabViewProps> = ({ sliderContent, selectedTab , setSelectedTab }) => {

    return (
        <div className={`${styles['tab-container']} ${styles['top-head']} `}>
            <div className={styles['tab-heads']}>
                {sliderContent.map((slide, index) => (
                    <div
                        className={`${styles['tab-head']} ${(selectedTab === slide.index) ? styles['active'] : ''}`}
                        key={'head-' + index}
                        onClick={() => { setSelectedTab(slide.index) }}
                    >
                        {/* <IoIosListBox /> */}
                        {slide.icon}
                    </div>
                ))}
            </div>
            <div className={styles['tab-contents']}>
                {sliderContent.map((slide, index) => (
                    <div
                        className={`${styles[`tab-content`]} ${(selectedTab === slide.index) ? styles['active'] : ''}`}
                        key={'data-' + index}
                    >
                        <h4 className={styles['text-center']}><b>{slide.title}</b></h4>
                        <p>{slide.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TabView;
