import React, { useEffect } from 'react';
import './About.css';
import visionStatement from '../Images/vision-statement-pana.svg';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';

const About: React.FC = () => {

    const scrollToTop = () => {
        setTimeout(()=> {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 200);
      };

    useEffect(() => {
        document.title = 'About | BreatheEV';
    }, []);    

    return (
        <main id="main">
            <section id="about-main">
                <div className="container text-center">
                    <h1>OUR SOLUTION</h1>
                    <h2>Your customers are ready to plug into something big. Are you?</h2><h2>Reach out to see how we can improve your current charging solution or how we can electrify your property and drive revenue to your site.</h2>
                    <Link to="/partner-with-us" onClick={scrollToTop} className="btn-get-started scrollto">Install Chargers</Link>
                </div>
            </section>

            <section id="future" className="container future  mt-3 bg-light mb-5">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-xl-5 col-lg-6 d-flex justify-content-center align-items-stretch position-relative">
                            <Image src={visionStatement} className='future-image' />
                        </div>

                        <div className="col-xl-7 col-lg-6 boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                            <div className='mb-1'>
                                <h3 className='text-center'>OUR FUTURE</h3>
                                <h4 className='text-center'>Redefining the Way We Charge</h4>
                                <p>BreatheEV is committed to pioneering the future of electric vehicle charging. We envision a world where charging an EV is as routine as charging a phone. Our roadmap includes expanding our network to new cities, integrating cutting-edge technologies for even smoother user experiences, and forging strategic partnerships to amplify our impact. We're not just planning to keep pace with the evolving world of EV charging – we aim to lead it, with innovative solutions that cater to the growing needs of EV owners and properties alike. Stay tuned as we shape a more sustainable, convenient, and connected future for EV charging.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </main>
    );
};

export default About;
