import React, { useState, useEffect, useRef } from 'react';
// import { FaCheck } from "react-icons/fa";
import { IoCheckmarkSharp } from "react-icons/io5";

import styles from './InputNumber.module.css';

interface InputNumberProps {
    value?: number | '';
    onChange?: (value: number | '') => void;
    label?: string;
    minValue?: number;
    maxValue?: number;
}

const InputNumber: React.FC<InputNumberProps> = ({ value = 0, onChange, label, minValue, maxValue }) => {
    const [internalValue, setInternalValue] = useState<number | ''>(value);
    const inputNumberRef = useRef<HTMLDivElement>(null);

    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    useEffect(() => {
        const handleScroll = (event: WheelEvent) => {
            if (inputNumberRef.current && event.target instanceof Node && inputNumberRef.current.contains(event.target as Node)) {
                if (event.deltaY > 0) {
                    decrement();
                } else {
                    increment();
                }
            }
        };

        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [minValue, maxValue, internalValue]);

    const handleChange = (newValue: number | '') => {
        setInternalValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    const increment = () => {
        let newValue: number | '';
        if (typeof internalValue === 'number' || internalValue === '') {
            newValue = (internalValue === '' ? 0 : internalValue) + 1;
        } else {
            newValue = internalValue;
        }
        if (typeof maxValue === 'number' && newValue > maxValue) {
            newValue = maxValue;
        }
        handleChange(newValue);
    };

    const decrement = () => {
        let newValue: number | '';
        if (typeof internalValue === 'number') {
            newValue = internalValue - 1;
            if (typeof minValue === 'number' && internalValue <= minValue) {
                newValue = minValue;
            }
        } else {
            newValue = internalValue;
        }
        handleChange(newValue);
    };

    const displayValue = internalValue === '' ? 0 : internalValue;

    return (
        <div ref={inputNumberRef} className={styles.inputNumber}>
            <button onClick={decrement} disabled={typeof minValue === 'number' && typeof internalValue === 'number' && internalValue <= minValue}>-</button>
            {isEditMode ? (
                <>
                    <input
                        min={minValue}
                        max={maxValue}
                        type='number'
                        value={internalValue}
                        onChange={(e) => { setInternalValue(Number(e.target.value)); handleChange(Number(e.target.value)); }}
                    />
                    <button onClick={()=>setIsEditMode(false)}><IoCheckmarkSharp /></button>
                </>

            ) : (
                <label className={styles.displayNumber} onClick={!isEditMode ? ()=>{setIsEditMode(true);} : undefined}>
                    {label && label !== 'Hours' ? (label && label !== '' ? label + ': ' : '') : undefined}
                    <b>{displayValue}</b>
                    {label === 'Hours' ? (' hours') : undefined}
                </label>
            )}
            <button onClick={increment} disabled={typeof maxValue === 'number' && typeof internalValue === 'number' && internalValue >= maxValue}>+</button>
        </div>
    );
};

export default InputNumber;
