import React, { useEffect } from 'react';
import './Blog.css';
import articles from './articles';
import { Link } from 'react-router-dom';


const Blog: React.FC = () => {

  useEffect(() => {
    document.title = 'Blog | BreatheEV';
  }, []);

  const scrollToTop = () => {
    setTimeout(()=> {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
  };

  const reversedArticles = [...articles].reverse();

  return (
    <main id="main">
      <section id="blog-main">
        <div className="container text-center">
          <h1>Blog</h1>
        </div>
      </section>
  
      <section id="blog-row" className="container blog-row  mt-3 bg-light mb-5">  
        <div className="container-fluid">

          <div className="row">
  
            {reversedArticles.map((article, index) => (
              <div key={'blog-'+index} className="col-md-6 blog">
                <div className="row g-0  bg-white  overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className='blog-image' style={{backgroundImage: `url(${article.image})`}}></div>
                  <div className="col p-4 d-flex flex-column position-static">
                    <strong className="d-inline-block mb-2 text-success">by {article.author}</strong>
                    <h5 className="mb-0">{article.title}</h5>
                    <div className="mb-1 text-muted">{article.date}</div>
                    <p className="card-text mb-auto">{article.content}</p>
                    <div className='blog-bottom'>
                      <Link onClick={() => { scrollToTop(); }} rel="noreferrer"  to={`/blog/${article.slug}`} className="stretched-link mt-2">Continue reading...</Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
  
          </div>
        </div>
      </section>
    </main>
  );
  
};

export default Blog;
